import React from 'react';
import logo from "./assets/images/bannerlogotopleft.png";
import companyLogo from "./assets/images/loogfinal.png";
import youtube from "./assets/images/ii1.png";
import dribble from "./assets/images/ii2.png"
import twitterIcon from "./assets/images/ii3.png";
import instagramIcon from "./assets/images/ii4.png";
import linkedinIcon from "./assets/images/ii5.png";
import emailbanner from "./assets/images/emailbanner.jpeg";

const EmailSignatureReview = () => {
  return (
    <div className="w-full max-w-4xl mx-auto relative">
      {/* Logo Overlay */}
      <div className="absolute top-4 left-4 z-10">
        <img 
          src={logo} 
          alt="Company Logo" 
          className="w-14 h-14 object-contain"
        />
      </div>

      {/* Top Image Section - 30% Height */}
      <div className="h-72 w-full">
        <img 
          src={emailbanner}
          alt="Email Signature Header" 
          className="w-full h-full object-cover"
        />
      </div>

      {/* Main Content Section */}
      <div className="w-full bg-[#B7BDCA] p-8">
        {/* Heading */}
        <h1 className="text-3xl font-['GeneralSansBold'] text-black text-center mb-7">
          Hey Rita, what do you think?
        </h1>

        {/* Horizontal Line */}
        <div className="w-full h-0.5 bg-black mb-10"></div>

        {/* Paragraph Text */}
        <p className="text-xl font-['GeneralSansMedium'] text-center text-[#0D182E] mb-10">
        Thank you for your shopping with us. Tell us what you think and we will send you $10 to thank you for your feedback. In learning about your experience, this help us growing and
        make your experience better every time.
        </p>

        {/* Review Now Button */}
        <div className="flex justify-center mb-20">
          <button 
            className="flex items-center justify-center bg-[#0D182E] text-white px-6 py-4 rounded-2xl hover:bg-gray-800 transition-colors w-3/4 font-[GeneralSansMedium] text-lg"
          >
            <span className="text-center">Review Now $10</span>
          </button>
        </div>

        <div className="w-full h-0.5 bg-black mb-10"></div>

        {/* Footer */}
        <div className="text-center">
          {/* Company Logo */}
          <div className="flex justify-center mb-4">
            <img 
              src={companyLogo} 
              alt="Company Logo" 
              className="h-20 w-auto"
            />
          </div>

          {/* Company Name */}
          <h2 className="text-2xl font-['VerminViva'] text-[#0D182E] mb-2">
            Review Pay
          </h2>

          <p className="font-['GeneralSansRegular'] text-black text-center mb-4">
            You are receiving this email because you opted in via our website.
          </p>

          {/* Social Media Icons */}
          <div className="flex justify-center space-x-4 mb-4">
            <a href="#" className="w-5 h-5">
              <img src={youtube} alt="Facebook" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={dribble} alt="Dribble" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={twitterIcon} alt="Twitter" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={instagramIcon} alt="Instagram" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={linkedinIcon} alt="LinkedIn" className="w-full h-full" />
            </a>
          </div>

          {/* Company Address */}
          <p className="text-sm font-['GeneralSansRegular'] text-black">
            123 Review Street, Tech City, Innovation District 12345
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailSignatureReview;