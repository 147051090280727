import React, { useState } from 'react';
import { 
  ArrowLeft, 
  ArrowRight, 
  Upload, 
  UserPlus,
  Mail,
  User,
  Clock,
  FileText,
  Building2,
  Receipt,
  ChevronLeft
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png";

const InputField = ({ label, value, onChange, type = "text", icon: Icon, placeholder }) => (
  <div className="relative mb-6">
    <label 
      className={`absolute left-4 text-white font-medium bg-[#0D182E] px-1 z-10 
        ${value ? '-top-2 text-xs text-[#B7BDCA]' : '-top-3'}`}
    >
      {label}
    </label>
    <div className="relative">
      {Icon && (
        <div className="absolute left-4 top-1/2 -translate-y-1/2">
          <Icon className="h-5 w-5 text-[#959DB0]" />
        </div>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full border border-[#959DB0] rounded-lg p-4 ${Icon ? 'pl-12' : 'pl-4'}
          bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16`}
      />
    </div>
  </div>
);

const EmployeeInvitation = () => {
  const [employees, setEmployees] = useState([{
    id: 1,
    profilePic: null,
    name: '',
    identificationNumber: '',
    designation: '',
    email: '',
    description: '',
    workingSince: ''
  }]);
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addEmployee = () => {
    setEmployees([...employees, {
      id: employees.length + 1,
      profilePic: null,
      name: '',
      identificationNumber: '',
      designation: '',
      email: '',
      description: '',
      workingSince: ''
    }]);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const accessToken = localStorage.getItem('accessToken');

    try {
      for (const employee of employees) {
        const formData = new FormData();
        formData.append('employee_name', employee.name);
        formData.append('identification_number', employee.identificationNumber);
        formData.append('designation', employee.designation);
        formData.append('working_since', formatDate(employee.workingSince));
        formData.append('employee_email_address', employee.email);
        
        // Only append if there's a file
        if (employee.profilePic instanceof File) {
          formData.append('employee_profiles', employee.profilePic);
        }

        const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/business/employee_detail', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          },
          body: formData
        });

        if(response.status === 401){
          localStorage.removeItem('accessToken');
          navigate('/signin');
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Employee added successfully:', data);
      }

      // Reset form after successful submission
      setEmployees([{
        id: 1,
        profilePic: null,
        name: '',
        identificationNumber: '',
        designation: '',
        email: '',
        description: '',
        workingSince: ''
      }]);

      alert('Employees added successfully!');
      
    } catch (error) {
      console.error('Error submitting employee data:', error);
      alert('Error submitting employee data. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = (index, files) => {
    if (files && files[0]) {
      const updatedEmployees = [...employees];
      updatedEmployees[index].profilePic = files[0]; // Store the File object directly
      setEmployees(updatedEmployees);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    setIsDragging(false);
    handleImageUpload(index, e.dataTransfer.files);
  };

  const removeImage = (index) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index].profilePic = null;
    setEmployees(updatedEmployees);
  };

  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="w-full flex justify-center pt-20 pb-8">
        <div className="text-center">
          <div className="space-y-8 font-['GeneralSansRegular']">
            <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          </div>
        </div>
      </div>

      {employees.map((employee, index) => (
        <div key={employee.id} className="bg-[#0D182E] rounded-2xl p-4 mx-4 mb-6">
          {/* File Upload */}
          <div 
            className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200 mb-6
              ${isDragging 
                ? 'border-[#959DB0] bg-[#1A2942] scale-[1.02]' 
                : 'border-[#959DB0] hover:border-white/50 bg-[#0D182E]'}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, index)}
            onClick={() => document.getElementById(`fileInput-${employee.id}`).click()}
          >
            <input
              id={`fileInput-${employee.id}`}
              type="file"
              className="hidden"
              accept="image/*"
              onChange={(e) => handleImageUpload(index, e.target.files)}
            />
            
            {employee.profilePic ? (
              <div className="relative">
                <img 
                  src={URL.createObjectURL(employee.profilePic)} 
                  alt="Profile" 
                  className="w-32 h-32 rounded-full object-cover mx-auto mb-4"
                />
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeImage(index);
                  }}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 
                             flex items-center justify-center text-xs hover:bg-red-600"
                >
                  ×
                </button>
              </div>
            ) : (
              <>
                <div className="bg-[#1A2942] rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                  <Upload className="h-8 w-8 text-[#959DB0]" />
                </div>
                <p className="text-sm font-['GeneralSansMedium'] text-[#959DB0] mb-1">
                  Click to upload or drag and drop
                </p>
                <p className="text-xs text-[#959DB0]">
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </p>
              </>
            )}
          </div>

          <InputField
            label="Full Name"
            value={employee.name}
            onChange={(e) => {
              const updatedEmployees = [...employees];
              updatedEmployees[index].name = e.target.value;
              setEmployees(updatedEmployees);
            }}
            placeholder="Enter full name"
            icon={User}
          />

          <InputField
            label="Identification Number"
            value={employee.identificationNumber}
            onChange={(e) => {
              const updatedEmployees = [...employees];
              updatedEmployees[index].identificationNumber = e.target.value;
              setEmployees(updatedEmployees);
            }}
            placeholder="Enter identification number"
            icon={Receipt}
          />

          <InputField
            label="Designation"
            value={employee.designation}
            onChange={(e) => {
              const updatedEmployees = [...employees];
              updatedEmployees[index].designation = e.target.value;
              setEmployees(updatedEmployees);
            }}
            placeholder="Enter designation"
            icon={Building2}
          />

          <InputField
            label="Description"
            value={employee.description}
            onChange={(e) => {
              const updatedEmployees = [...employees];
              updatedEmployees[index].description = e.target.value;
              setEmployees(updatedEmployees);
            }}
            placeholder="Enter employee description"
            icon={FileText}
          />

          <InputField
            label="Working Since"
            value={employee.workingSince}
            onChange={(e) => {
              const updatedEmployees = [...employees];
              updatedEmployees[index].workingSince = e.target.value;
              setEmployees(updatedEmployees);
            }}
            type="date"
            icon={Clock}
          />

          <InputField
            label="Email Address"
            value={employee.email}
            onChange={(e) => {
              const updatedEmployees = [...employees];
              updatedEmployees[index].email = e.target.value;
              setEmployees(updatedEmployees);
            }}
            placeholder="Enter email address"
            icon={Mail}
            type="email"
          />
        </div>
      ))}

      <button
        type="button"
        onClick={addEmployee}
        className="bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-2 flex items-center justify-center"
      >
        <UserPlus className="w-5 h-5 mr-2 text-white" />
        <span className="text-white text-lg font-bold font-['GeneralSansMedium']">
          Add Another Employee
        </span>
      </button>

      <button
        type="button"
        onClick={handleSubmit}
        disabled={isSubmitting}
        className="bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-2 flex items-center justify-center"
      >
        <span className="text-white text-lg font-bold font-['GeneralSansMedium']">
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </span>
      </button>
    </div>
  );
};

export default EmployeeInvitation;