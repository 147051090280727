import React, { useState } from 'react';
import { Search, ArrowUpDown, Calendar, MoreVertical, Share2Icon, QrCode } from 'lucide-react';
import product1 from "./assets/images/noburestaurent.png";
import product2 from "./assets/images/noburestaurent.png";
import product3 from "./assets/images/noburestaurent.png";
import logo from "./assets/images/loogfinal.png";

const ReviewsPage = () => {
  const initialReviewData = [
    {
      id: 1,
      businessName: "Ink Masters Studio",
      industry: "Tattoo & Piercing",
      image: product1,
      businesslocation: 'NewYork , NY',
      businessLogo: logo,
      productName: "Premium Tattoo Ink Set",
      price: 129.99,
      rating: 95,
      dateAdded: "2024-01-15",
      expiryDate: "2024-12-31",
      reviewCashback: 25.00,
      referralCodeExpiry: "2024-06-30",
      referralCashbackPercent: 15,
      status:'paid',
    },
    {
      id: 2,
      businessName: "Pro Tattoo Supply",
      industry: "Tattoo & Piercing",
      image: product2,
      businesslocation: 'NewYork , NY',
      businessLogo: logo,
      productName: "Artist Pro Tattoo Machine",
      price: 349.99,
      rating: 88,
      dateAdded: "2024-02-20",
      expiryDate: "2024-12-31",
      reviewCashback: 50.00,
      referralCodeExpiry: "2024-07-31",
      referralCashbackPercent: 20,
      status:'pending review',
    },
    {
      id: 3,
      businessName: "Tattoo Care Plus",
      industry: "Personal Care",
      image: product3,
      businesslocation: 'NewYork , NY',
      businessLogo: logo,
      productName: "Healing Tattoo Care Kit",
      price: 49.99,
      rating: 92,
      dateAdded: "2024-03-10",
      expiryDate: "2024-12-31",
      reviewCashback: 15.00,
      referralCodeExpiry: "2024-08-31",
      referralCashbackPercent: 10,
      status:'paid',
    }
  ];


  // State management
  const [reviewData, setReviewData] = useState(initialReviewData);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');


  // Date filtering helper function
  const isWithinDateRange = (dateString, filter) => {
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...reviewData];

    switch (option) {
      case 'business-asc':
        sortedData.sort((a, b) => a.businessName.localeCompare(b.businessName));
        break;
      case 'business-desc':
        sortedData.sort((a, b) => b.businessName.localeCompare(a.businessName));
        break;
      case 'industry':
        sortedData.sort((a, b) => a.industry.localeCompare(b.industry));
        break;
      case 'price-high':
        sortedData.sort((a, b) => b.price - a.price);
        break;
      case 'price-low':
        sortedData.sort((a, b) => a.price - b.price);
        break;
      case 'cashback-high':
        sortedData.sort((a, b) => b.reviewCashback - a.reviewCashback);
        break;
      case 'cashback-low':
        sortedData.sort((a, b) => a.reviewCashback - b.reviewCashback);
        break;
      default:
        break;
    }

    setReviewData(sortedData);
  };

  // Filter reviews (updated to include new fields)
  const filteredReviews = reviewData.filter(review => 
    (review.businessName.toLowerCase().includes(nameFilter.toLowerCase()) ||
    review.productName.toLowerCase().includes(nameFilter.toLowerCase()) ||
    review.industry.toLowerCase().includes(nameFilter.toLowerCase())) &&
    isWithinDateRange(review.dateAdded, dateFilter)
  );
  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section - Made scrollable for small screens */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 overflow-x-auto">
        <div className="flex flex-nowrap min-w-max sm:min-w-0 sm:flex-row gap-4">
          {/* Search */}
          <div className="w-72 sm:w-auto sm:flex-1">
            <label className="text-white font-['GeneralSansMedium'] mb-2 block">
              Search Reviews
            </label>
            <div className="relative">
              <input
                type="text"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                placeholder="Search by business or product"
                className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
              />
              <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
                <Search className="text-white" size={20} />
              </div>
            </div>
          </div>

          {/* Sort Options */}
          <div className="w-72 sm:w-auto sm:flex-1">
            <label className="text-white font-['GeneralSansMedium'] mb-2 block">
              Sort By
            </label>
            <div className="relative">
              <select 
                value={sortOrder}
                onChange={(e) => handleSort(e.target.value)}
                className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
              >
                <option value="">Select Sort Option</option>
                <option value="business-asc">Business Name (A-Z)</option>
                <option value="business-desc">Business Name (Z-A)</option>
                <option value="price-high">Price (High-Low)</option>
                <option value="price-low">Price (Low-High)</option>
                <option value="rating-high">Rating (High-Low)</option>
                <option value="rating-low">Rating (Low-High)</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
                <ArrowUpDown className="text-white" size={20} />
              </div>
            </div>
          </div>

          {/* Date Filter */}
          <div className="w-72 sm:w-auto sm:flex-1">
            <label className="text-white font-['GeneralSansMedium'] mb-2 block">
              Filter by Date
            </label>
            <div className="relative">
              <select
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
                className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
              >
                <option value="all">All Time</option>
                <option value="week">Last Week</option>
                <option value="month">Last Month</option>
                <option value="year">Last Year</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
                <Calendar className="text-white" size={20} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reviews Table Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">My Reviews</h2>
        <div className="overflow-x-auto">
          <div className="min-w-max">
            <table className="w-full">
              <thead>
                <tr className="bg-[#1a2942]">
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Business Name</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Industry</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Product</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Price</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Rating</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Expiry Date</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Review Cashback</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Referral Expiry</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Referral %</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Status</th>
                  <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredReviews.map((review) => (
                  <tr key={review.id} className="hover:bg-[#1a2942]">
                    {/* First 7 columns remain the same */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-3">
                        <div className="w-10 h-10 rounded-lg overflow-hidden flex-shrink-0">
                          <img 
                            src={review.businessLogo} 
                            className="w-full h-full object-contain"
                            alt={review.businessName}
                          />
                        </div>
                        <div>
                          <span className="font-['GeneralSansRegular'] text-white block">{review.businessName}</span>
                          <span className="font-['GeneralSansRegular'] text-xs text-white">{review.businesslocation}</span>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-['GeneralSansRegular'] text-white">{review.industry}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-4">
                        <div className="w-10 h-10 rounded-lg overflow-hidden flex-shrink-0">
                          <img src={review.image} alt={review.productName} className="w-full h-full object-cover" />
                        </div>
                        <span className="font-['GeneralSansRegular'] text-white">{review.productName}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">${review.price}</span>
                    </td>
                    <td className="px-6 py-4">
                      <div className="w-24">
                        <div className="w-full bg-[#DC3DF7] rounded-full h-2">
                          <div 
                            className="bg-[#3DE9DC] h-2 rounded-full" 
                            style={{ width: `${review.rating}%` }}
                          ></div>
                        </div>
                        <span className="text-white text-sm font-['GeneralSansRegular']">{review.rating}%</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-['GeneralSansRegular'] text-white">{review.expiryDate}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">${review.reviewCashback}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-['GeneralSansRegular'] text-white">
                        {review.status !== 'pending review' ? review.referralCodeExpiry : '-'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">
                        {review.status !== 'pending review' ? `${review.referralCashbackPercent}%` : '-'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex px-3 py-1 text-sm rounded-full font-['GeneralSansRegular'] ${
                        review.status === 'paid' ? 'bg-[#65D4B0] text-white' : 
                        review.status === 'pending review' ? 'bg-[#FE8705] text-white' : 
                        'bg-[#DB005F] text-white'
                      }`}>
                        {review.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex space-x-3">
                        {review.status !== 'pending review' && (
                          <button className="p-2 hover:bg-[#1A2E56] rounded-lg transition-colors">
                            <QrCode className="text-white" size={20} />
                          </button>
                        )}
                        <button className="p-2 hover:bg-[#1A2E56] rounded-lg transition-colors">
                          <Share2Icon className="text-white" size={20} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* No Results Message */}
            {filteredReviews.length === 0 && (
              <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
                No reviews found matching the selected filters.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsPage;