import React, { useState } from 'react';
import userProfileBg from "./assets/images/imgspell.png";
import userAvatar from "./assets/images/happyman.png";
import mapicon from "./assets/images/Layer 2.png"
import img1 from "./assets/images/cafe.png"
import img2 from './assets/images/imagestatic1.png'
import img3 from "./assets/images/restfav.png"
import profile from "./assets/images/profile.png";
import logo from "./assets/images/loogfinal.png";
import logoauth from "./assets/images/logoauthentic.png"
import placesToVisitIcon from "./assets/images/tabicon1.png";
import myShopIcon from "./assets/images/tabicon2.png";
import favoritesIcon from "./assets/images/heart.png";
import reviewsIcon from "./assets/images/Existing Review.png"; // Assuming you'll add a reviews icon
import { Navigate , useNavigate } from 'react-router-dom';
import SmallDial from './SmallDial';
import logo2 from "./assets/images/logoauthentic.png";
import image1 from "./assets/images/teammember3.jpeg"
import image2 from "./assets/images/bimage.png"
import image3  from "./assets/images/image_lion.png"
import image4 from "./assets/images/teammember1.jpeg"


const ReviewImageCarousel = ({ images, selectedImage, onImageSelect }) => {
  return (
    <div className="w-full">
      {/* Main Image Display */}
      <div className="w-full h-64 md:h-96 mb-4 rounded-lg overflow-hidden  flex items-center justify-center">
        <img 
          src={images[selectedImage]} 
          alt={`Review ${selectedImage + 1}`} 
          className="max-w-full max-h-full w-auto h-auto object-contain"
        />
      </div>
      
      {/* Thumbnail Strip */}
      <div className="flex gap-2 overflow-x-auto pb-2 justify-center">
        {images.map((img, idx) => (
          <button 
            key={idx}
            onClick={() => onImageSelect(idx)}
            className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden border-2 bg-[#1a2942] flex items-center justify-center
              ${selectedImage === idx ? 'border-[#DC3DF7]' : 'border-transparent'}`}
          >
            <img 
              src={img} 
              alt={`Thumbnail ${idx + 1}`} 
              className="max-w-full max-h-full w-auto h-auto object-contain"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

const PerformanceBar = ({ label, value, percentage }) => (
  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
    <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
    </div>
    <div className="flex items-center space-x-4 w-full sm:w-3/4">
      <div className="flex-grow bg-[#DC3DF7] h-3 rounded-full">
        <div 
          className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
        {value}/10
      </span>
    </div>
  </div>
);

const ReviewSectionn = ({ review, currentReviewIndex, setCurrentReviewIndex, reviewsData }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  

  const reviewImages = [
    image1,
    image2,
    image3,
    image4
  ];

  return (
    <div className=" rounded-lg shadow-md p-6">
      {/* Row 1: Split into two columns */}
      <div className="flex flex-col lg:flex-row gap-6 mb-6">
        {/* Left Column: Customer Details */}
        <div className="w-full lg:w-1/2">
          <div className="flex items-start space-x-4 mb-6">
            <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
              <img src={profile} alt="Profile" className="w-full h-full object-cover" />
            </div>
            <div>
              <h3 className="font-['GeneralSansBold'] text-white text-xl mb-1">{review.name}</h3>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm mb-2">{review.subtitle}</p>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">{review.date}</p>
            </div>
          </div>




          <div className="mb-4">
            <h4 className="text-white font-['GeneralSansBold'] text-lg mb-2">
              Customer Experience: {review.customerExperience}
            </h4>
            <p className="font-['GeneralSansRegular'] text-gray-300 text-base">
              {review.review}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row   sm:items-center mb-6 space-y-4 sm:space-y-0 -ml-10 lg:ml-10">
          <img
                      src={logo2}
                      alt="Authentic"
                      className="w-28 sm:w-36 h-8 sm:h-10 ml-10 lg:-ml-10 mr-40"
                    />
                  <SmallDial currentPerformance={{ value:70 }}  />

                  </div>
        </div>

        {/* Right Column: Image Carousel */}
        <div className="w-full lg:w-1/2">
          <ReviewImageCarousel 
            images={reviewImages}
            selectedImage={selectedImage}
            onImageSelect={setSelectedImage}
          />
        </div>
      </div>

      {/* Row 2: Performance Bars */}
      <div className="mt-6">
        <h4 className="text-white font-['GeneralSansBold'] text-lg mb-4">Performance Metrics</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
          {review.performanceBars.map((bar, index) => (
            <PerformanceBar 
              key={index}
              label={bar.label}
              value={bar.value}
              percentage={bar.percentage}
            />
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-center gap-4 mt-6">
        <button 
          className="px-6 py-2 rounded-lg font-['GeneralSansBold'] text-base bg-[#B7BDCA] text-[#0D182E]"
        >
          Report    
        </button>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-6 space-x-2">
        {reviewsData.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentReviewIndex(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300
              ${currentReviewIndex === index ? 'bg-[#527DE6]' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};


const UserProfile = () => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [activeTab, setActiveTab] = useState('placesToVisit');
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const navigate = useNavigate();


  const handlemyshopnav = () =>{
    navigate('/UserDashboard/MyShopList');
  }
  const handleplacesvisit = () =>{
    navigate('/UserDashboard/MyPlaceToVisit');
  }
  const progressData = [
    { label: 'Reviews', value: 75 },
    { label: 'Referrals', value: 60 },
    { label: 'Followers', value: 90 },
    { label: 'Level', value: 60 },
    { label: 'Brand Ambassador', value: 50 },
    { label: 'Popularity', value: 70 },
  ];

  const placesToVisitData = [
    { id: 1, name: 'Beach Resort', image: img1 },
    { id: 2, name: 'Mountain Cabin', image: img1  },
    { id: 3, name: 'City Apartment', image: img1  },
    { id: 4, name: 'Beach Resort', image: img1 },
    { id: 5, name: 'Mountain Cabin', image: img1  },
    { id: 6, name: 'City Apartment', image: img1  },
    { id: 7, name: 'Beach Resort', image: img1 },
    { id: 8, name: 'Mountain Cabin', image: img1  },
    { id: 9, name: 'City Apartment', image: img1  },
  ];

  const myShopData = [
    { id: 1, name: 'Product 1', image: img2 },
    { id: 2, name: 'Product 2', image: img2 },
    { id: 3, name: 'Product 3', image: img2 }
  ];

  const favoritesData = [
    { id: 1, name: 'Favorite Place 1', image: img3 },
    { id: 2, name: 'Favorite Place 2', image: img3 },
    { id: 3, name: 'Favorite Place 3', image: img3 }
  ];
  

  const reviewsData = [
    {
      id: 1,
      name: 'Floyd Miles',
      subtitle: '147 Reviews 23 Referrals - Brand Ambassador',
      date: '1 Month Ago',
      customerExperience: '99/100',
      review: 'First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking. However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      subtitle: '82 Reviews 15 Referrals - Bronze Ambassador',
      date: '2 Months Ago',
      customerExperience: '95/100',
      review: 'An incredible experience from start to finish. The attention to detail and professionalism was outstanding. I would highly recommend this service to anyone looking for top-quality work.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 3,
      name: 'Michael Chen',
      subtitle: '65 Reviews 10 Referrals - Silver Ambassador',
      date: '3 Months Ago',
      customerExperience: '92/100',
      review: 'A fantastic service that exceeded my expectations. The team was knowledgeable, friendly, and truly passionate about what they do. Im looking forward to working with them again.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    }
  ];



  const PerformanceBar = ({ label, value, percentage }) => (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
      <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
      </div>
      <div className="flex items-center space-x-4 w-full sm:w-3/4">
        <div className="flex-grow bg-[#DC3DF7] h-3 rounded-full">
          <div 
            className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
          {value}/10
        </span>
      </div>
    </div>
  );
  return (
    <div className="w-full p-8 space-y-8" style={{fontFamily: 'GeneralSansRegular'}}>


      {/* Profile Container with Reduced Opacity Background */}
      <div className="relative w-full h-[500px] rounded-2xl overflow-hidden border-4 border-black mt-24">
        <div 
          className="absolute inset-0 bg-[#527DE6]" 
          style={{
            backgroundImage: `url(${userProfileBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.3 // Reduced opacity for imgspell background
          }}
        />

      

        <div className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <img 
            src={userAvatar} 
            alt="User Avatar" 
            className="w-full h-full object-contain mt-20"
          />
        </div>

        <div className="absolute bottom-8 left-2 sm:left-8">
          <h1 
            className=" text-2xl sm:text-3xl text-white font-[VarinoRegular]"
            style={{ fontFamily: 'VarinoRegular' }}
          >
            John Doe
          </h1>
        </div>

        <div className="absolute bottom-5 right-8">
          <button 
            onClick={() => setIsFollowing(!isFollowing)}
            className={`px-3 py-2 rounded-lg transition-colors duration-300 ${
              isFollowing 
                ? 'bg-[#B7BDCA] text-[#0D182E]' 
                : 'bg-[#0D182E] text-white'
            }`}
            style={{ fontFamily: 'GeneralSansMedium' }}
          >
            {isFollowing ? 'Following' : 'Follow Me'}
          </button>
        </div>
      </div>

      {/* Progress Bars Section */}
      <div className="relative w-full  rounded-2xl p-6 flex items-center">
        <div className="w-2/3">
          {progressData.map((item, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between mb-2">
                <span className="font-[GeneralSansMedium] text-[#0D182E]">{item.label}</span>
                <span className="font-[GeneralSansMedium] text-[#0D182E]">{item.value}</span>
              </div>
              <div className="w-full bg-white rounded-full h-3">
                <div 
                  className="bg-[#DC3DF7] h-3 rounded-full" 
                  style={{width: `${item.value}%`}}
                ></div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-1/3 pl-6">
          <img 
            src={mapicon} 
            alt="Large Profile" 
            className="w-full h-auto"
          />
        </div>
      </div>


      <div className="w-full rounded-2xl p-6">
      <div className="flex justify-center space-x-8">
        <button
          onClick={handleplacesvisit}
          className="flex flex-col items-center px-6 py-4 rounded-lg 
                     transition-all duration-300 hover:bg-[#527DE6]/10"
        >
          <div className="w-16 h-16 rounded-full flex items-center justify-center mb-2">
            <img
              src={placesToVisitIcon}
              alt="Places To Visit"
              className="w-8 h-8 transition-transform duration-300 hover:scale-105"
            />
          </div>
          <span className="text-sm font-['GeneralSansBold'] text-[#0D182E]">
            Places To Visit
          </span>
        </button>

        <button
          onClick={handlemyshopnav}
          className="flex flex-col items-center px-6 py-4 rounded-lg 
                     transition-all duration-300 hover:bg-[#527DE6]/10"
        >
          <div className="w-16 h-16 rounded-full flex items-center justify-center mb-2">
            <img
              src={myShopIcon}
              alt="My Shop"
              className="w-8 h-8 transition-transform duration-300 hover:scale-105"
            />
          </div>
          <span className="text-sm font-['GeneralSansBold'] text-[#0D182E]">
            My Shop
          </span>
        </button>
      </div>
    </div>

      {/* Favorites Section */}
      <div className="w-full  rounded-2xl p-6">
        <div className="flex items-center mb-4">
          <img src={favoritesIcon} alt="Favorites" className="w-8 h-8 mr-4" />
          <h2 className="text-2xl font-semibold text-[#0D182E] font-['GeneralSansBold']">Favorites</h2>
        </div>

        <div className="flex overflow-x-auto space-x-4 pb-4">
          {favoritesData.map((item) => (
            <div key={item.id} className="flex-shrink-0 w-64 bg-[#B7BDCA] text-[#0D182E] rounded-lg  ">
              <img 
                src={item.image} 
                alt={item.name} 
                className="w-full h-48 object-cover rounded-lg"
              />
              <div className="p-4">
                <h3 className="text-lg font-[GeneralSansMedium]">{item.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

           <section className=" mx-auto bg-[#0D182E] rounded-xl shadow-lg p-6 m-4 ">
                  {/* Header Section */}
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
                    <div className="flex items-center">
                      <div className="flex items-center">
                        <img 
                          src={reviewsIcon} 
                          alt="Reviews" 
                          className="w-8 h-8 mr-3" 
                        />
                        <h2 className="text-2xl font-['GeneralSansBold'] text-white">
                          Existing Reviews
                        </h2>
                      </div>
                    </div>
                    
                    {/* Navigation Buttons */}
                    <div className="flex items-center gap-3">
                      <button 
                        onClick={() => setCurrentReviewIndex(Math.max(0, currentReviewIndex - 1))}
                        className="p-3 rounded-full bg-[#527DE6] text-white hover:bg-[#4267c7] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={currentReviewIndex === 0}
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          className="w-5 h-5"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M15 19l-7-7 7-7" 
                          />
                        </svg>
                      </button>
                      
                      <div className="bg-[#1a2942] px-4 py-2 rounded-lg">
                        <span className="text-white font-['GeneralSansRegular']">
                          {currentReviewIndex + 1} / {reviewsData.length}
                        </span>
                      </div>
                      
                      <button 
                        onClick={() => setCurrentReviewIndex(Math.min(reviewsData.length - 1, currentReviewIndex + 1))}
                        className="p-3 rounded-full bg-[#527DE6] text-white hover:bg-[#4267c7] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={currentReviewIndex === reviewsData.length - 1}
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          className="w-5 h-5"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M9 5l7 7-7 7" 
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
            
                  {/* Review Content */}
                  <div className="mt-4">
                    <ReviewSectionn 
                      review={reviewsData[currentReviewIndex]}
                      currentReviewIndex={currentReviewIndex}
                      setCurrentReviewIndex={setCurrentReviewIndex}
                      reviewsData={reviewsData}
                    />
                  </div>
                </section>
    </div>
  );
};

export default UserProfile;