import React, { useState } from 'react';
import { Search, ArrowUpDown, Calendar, ChevronDown } from 'lucide-react';
import ProfileImage from './assets/images/profile.png';
import logo from "./assets/images/loogfinal.png";

const Feedback = () => {
  const initialFeedbackData = [
    { 
      id: 1,
      backgroundColor: 'bg-[#527DE6]', 
      senderName: 'Kat Larsson',
      email: 'KatLarsson@gmail.com', 
      phone: '(469) 532 2382',
      location: 'NewYork,Ny',
      status: 'Received',
      template: 'Customer Experience Survey',
      submissionDate: '2024-01-15'
    },
    { 
      id: 2,
      backgroundColor: 'bg-[#65D4B0]', 
      senderName: 'Mike Johnson',
      email: 'Mikejohnson@gmail.com',
      phone: '(469) 532 2383',
      location: 'NewYork,Ny',
      status: 'Pending',
      template: 'Service Quality Review',

      submissionDate: '2024-01-20'
    }
    ,
    { 
      id: 3,
      backgroundColor: 'bg-[#65D4B0]', 
      senderName: 'Sarah Smith',
      email: 'Sarahsmith@gmail.com',
      phone: '(469) 532 2383',
      location: 'Canada,Mt',
      status: 'Received',
      template: 'Offering Discounts',
      submissionDate: '2023-04-10'
    }
  ];

  // State management
  const [feedbackData, setFeedbackData] = useState(initialFeedbackData);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');

  // Date filtering function
  const isWithinDateRange = (dateString, filter) => {
    if (!dateString) return true;
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...feedbackData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.senderName.localeCompare(b.senderName));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.senderName.localeCompare(a.senderName));
        break;
      case 'date-asc':
        sortedData.sort((a, b) => new Date(a.submissionDate) - new Date(b.submissionDate));
        break;
      case 'date-desc':
        sortedData.sort((a, b) => new Date(b.submissionDate) - new Date(a.submissionDate));
        break;
      default:
        break;
    }

    setFeedbackData(sortedData);
  };

  const filteredFeedback = feedbackData.filter(feedback => {
    const searchTerm = nameFilter.toLowerCase();
    const matchesSearch = !searchTerm ||
      feedback.senderName.toLowerCase().includes(searchTerm) ||
      feedback.email.toLowerCase().includes(searchTerm);
    const matchesDate = isWithinDateRange(feedback.submissionDate, dateFilter);
    const matchesStatus = statusFilter === 'all' || feedback.status.toLowerCase() === statusFilter.toLowerCase();
    
    return matchesSearch && matchesDate && matchesStatus;
  });

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Search Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search for clients
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name or email"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="date-asc">Date (Oldest First)</option>
              <option value="date-desc">Date (Newest First)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Status Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Status
          </label>
          <div className="relative">
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Status</option>
              <option value="received">Received</option>
              <option value="pending">Pending</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ChevronDown className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>
      
      {/* Feedback Table */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">Feedback From Clients</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="bg-[#1a2942]">
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Clients</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Template Name</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Status</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Submission Date</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {filteredFeedback.map((feedback) => (
                <tr key={feedback.id} className="hover:bg-[#1a2942]">
                  <td className="px-4 sm:px-6 py-4">
                    <div className="flex items-center space-x-4">
                      <div className={`w-10 h-10 transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${feedback.backgroundColor}`}>
                        <div className="w-full h-full -rotate-45 overflow-hidden">
                          <img 
                            src={ProfileImage} 
                            alt="Profile" 
                            className="w-[130%] h-[105%] object-contain absolute" 
                          />
                        </div>
                      </div>
                      <div>
                        <p className="font-['GeneralSansRegular'] text-white">{feedback.senderName}</p>
                        <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{feedback.email}</p>
                        <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{feedback.location}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 sm:px-6 py-4">
                    <span className="font-['GeneralSansRegular'] text-white">{feedback.template}</span>
                  </td>
                  <td className="px-4 sm:px-6 py-4">
                    <span className={`px-3 py-1 rounded-full text-sm font-['GeneralSansRegular'] ${
                      feedback.status === 'Received' ? 'bg-green-900 text-green-300' : 'bg-yellow-900 text-yellow-300'
                    }`}>
                      {feedback.status}
                    </span>
                  </td>
                  <td className="px-4 sm:px-6 py-4">
                    <span className="font-['GeneralSansRegular'] text-white">
                      {new Date(feedback.submissionDate).toLocaleDateString()}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {filteredFeedback.length === 0 && (
            <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
              No feedback found matching the selected filters.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feedback;