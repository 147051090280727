import React, { useState } from 'react';
import logo from './assets/images/loogfinal.png';

const ComingSoonPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('email', formData.email);
      formDataToSend.append('name', formData.name);
      formDataToSend.append('phone_number', formData.phone_number);
      const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/commingsoon', {
        method: 'POST',
        body: formDataToSend
      });

      const responseData = await response.json(); 

      if (response.ok) {
        alert(responseData.message || 'Thank you for signing up! We will contact you soon.');
        setFormData({ name: '', email: '', phone_number: '' });
      } else {
        alert(responseData.error || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4 py-12"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8">
        <img 
          src={logo} 
          alt="Review Pay Logo" 
          className="mx-auto mb-6"
          width={150} 
        />

        <h1 className="text-white text-2xl font-['GeneralSansMedium'] mb-8 px-4">
          Be the first to experience our revolutionary web app—sign up now for exclusive early access and unlock exciting features before anyone else!
        </h1>

        <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md mx-auto border border-white/20 shadow-2xl">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Full Name"
                required
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email Address"
                required
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>
            <div>
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                placeholder="Phone Number"
                required
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white 
                         py-3 px-4 rounded-lg font-['GeneralSansBold'] hover:opacity-90 
                         transition-all duration-300 shadow-lg hover:shadow-xl 
                         hover:scale-[1.02]"
            >
              Get Early Access
            </button>
          </form>
        </div>
      </div>

      <p className="text-gray-200 font-['GeneralSansRegular'] text-sm mt-8">
        © {new Date().getFullYear()} Review Pay. All rights reserved.
      </p>
    </div>
  );
};

export default ComingSoonPage;