import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer , RadialBarChart, RadialBar
} from 'recharts';
import { 
  Mail, 
  Phone, 
  MapPin, 
  Globe, 
  Instagram, 
  Facebook, 
  Utensils, 
  Handshake,
  Clock
} from 'lucide-react';
import video1 from "./assets/images/reviewvideo.mp4";
import logo1 from "./assets/images/avatar2.png";
import { useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png"; 
import leftImage from "./assets/images/hand_mobile.png";
import rightImage from "./assets/images/referral_image.png";
import logoauth from "./assets/images/logoauthentic.png"
import reviewsIcon from "./assets/images/Existing Review.png";
import profile from "./assets/images/profile.png";
import SmallDial from './SmallDial';
import mapicon from "./assets/images/Layer 2.png"
import logo2 from "./assets/images/logoauthentic.png";
import image1 from "./assets/images/teammember3.jpeg"
import image2 from "./assets/images/bimage.png"
import image3  from "./assets/images/image_lion.png"
import image4 from "./assets/images/teammember1.jpeg"


const performanceData = [
  { name: '12 Months', value: 100, reviews: 1200 },
  { name: '30 Days', value: 85, reviews: 350 },
  { name: '7 Days', value: 10, reviews: 80 },
];

const ReviewImageCarousel = ({ images, selectedImage, onImageSelect }) => {
  return (
    <div className="w-full">
      {/* Main Image Display */}
      <div className="w-full h-64 md:h-96 mb-4 rounded-lg overflow-hidden  flex items-center justify-center">
        <img 
          src={images[selectedImage]} 
          alt={`Review ${selectedImage + 1}`} 
          className="max-w-full max-h-full w-auto h-auto object-contain"
        />
      </div>
      
      {/* Thumbnail Strip */}
      <div className="flex gap-2 overflow-x-auto pb-2 justify-center">
        {images.map((img, idx) => (
          <button 
            key={idx}
            onClick={() => onImageSelect(idx)}
            className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden border-2 bg-[#1a2942] flex items-center justify-center
              ${selectedImage === idx ? 'border-[#DC3DF7]' : 'border-transparent'}`}
          >
            <img 
              src={img} 
              alt={`Thumbnail ${idx + 1}`} 
              className="max-w-full max-h-full w-auto h-auto object-contain"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

const PerformanceBar = ({ label, value, percentage }) => (
  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
    <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
    </div>
    <div className="flex items-center space-x-4 w-full sm:w-3/4">
      <div className="flex-grow bg-[#DC3DF7] h-3 rounded-full">
        <div 
          className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
        {value}/10
      </span>
    </div>
  </div>
);

const ReviewSectionn = ({ review, currentReviewIndex, setCurrentReviewIndex, reviewsData }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  

  const reviewImages = [
    image1,
    image2,
    image3,
    image4
  ];

  return (
    <div className=" rounded-lg shadow-md p-6">
      {/* Row 1: Split into two columns */}
      <div className="flex flex-col lg:flex-row gap-6 mb-6">
        {/* Left Column: Customer Details */}
        <div className="w-full lg:w-1/2">
          <div className="flex items-start space-x-4 mb-6">
            <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
              <img src={profile} alt="Profile" className="w-full h-full object-cover" />
            </div>
            <div>
              <h3 className="font-['GeneralSansBold'] text-white text-xl mb-1">{review.name}</h3>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm mb-2">{review.subtitle}</p>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">{review.date}</p>
            </div>
          </div>




          <div className="mb-4">
            <h4 className="text-white font-['GeneralSansBold'] text-lg mb-2">
              Customer Experience: {review.customerExperience}
            </h4>
            <p className="font-['GeneralSansRegular'] text-gray-300 text-base">
              {review.review}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row   sm:items-center mb-6 space-y-4 sm:space-y-0 -ml-10 lg:ml-10">
          <img
                      src={logo2}
                      alt="Authentic"
                      className="w-28 sm:w-36 h-8 sm:h-10 ml-10 lg:-ml-10 mr-40"
                    />
                  <SmallDial currentPerformance={{ value:70 }}  />

                  </div>
        </div>

        {/* Right Column: Image Carousel */}
        <div className="w-full lg:w-1/2">
          <ReviewImageCarousel 
            images={reviewImages}
            selectedImage={selectedImage}
            onImageSelect={setSelectedImage}
          />
        </div>
      </div>

      {/* Row 2: Performance Bars */}
      <div className="mt-6">
        <h4 className="text-white font-['GeneralSansBold'] text-lg mb-4">Performance Metrics</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
          {review.performanceBars.map((bar, index) => (
            <PerformanceBar 
              key={index}
              label={bar.label}
              value={bar.value}
              percentage={bar.percentage}
            />
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-center gap-4 mt-6">
        <button 
          className="px-6 py-2 rounded-lg font-['GeneralSansBold'] text-base bg-[#B7BDCA] text-[#0D182E]"
        >
          Respond    
        </button>
        <button 
          className="px-6 py-2 rounded-lg font-['GeneralSansBold'] text-base bg-[#B7BDCA] text-[#0D182E]"
        >
          Report    
        </button>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-6 space-x-2">
        {reviewsData.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentReviewIndex(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300
              ${currentReviewIndex === index ? 'bg-[#527DE6]' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};


const ReviewSection = () => {
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState('12 months');
  const [isFollowing, setIsFollowing] = useState(false);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [currentPerformance, setCurrentPerformance] = useState(performanceData[0]);
 
  const chartData = {
    '12 months': [
      { month: 'Jan', reviews: 40, referrals: 24 },
      { month: 'Feb', reviews: 35, referrals: 28 },
      { month: 'Mar', reviews: 45, referrals: 32 },
      { month: 'Apr', reviews: 50, referrals: 36 },
      { month: 'May', reviews: 42, referrals: 30 },
      { month: 'Jun', reviews: 48, referrals: 35 }
    ],
    '30 days': [
      { month: 'Week 1', reviews: 15, referrals: 8 },
      { month: 'Week 2', reviews: 18, referrals: 10 },
      { month: 'Week 3', reviews: 22, referrals: 12 },
      { month: 'Week 4', reviews: 25, referrals: 15 }
    ],
    '7 days': [
      { month: 'Day 1', reviews: 5, referrals: 3 },
      { month: 'Day 2', reviews: 6, referrals: 4 },
      { month: 'Day 3', reviews: 7, referrals: 5 },
      { month: 'Day 4', reviews: 8, referrals: 6 },
      { month: 'Day 5', reviews: 9, referrals: 7 },
      { month: 'Day 6', reviews: 10, referrals: 8 },
      { month: 'Day 7', reviews: 11, referrals: 9 }
    ]
  };

  const reviewsData = [
    {
      id: 1,
      name: 'Floyd Miles',
      subtitle: '147 Reviews 23 Referrals - Brand Ambassador',
      date: '1 Month Ago',
      customerExperience: '99/100',
      review: 'First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking. However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      subtitle: '82 Reviews 15 Referrals - Bronze Ambassador',
      date: '2 Months Ago',
      customerExperience: '95/100',
      review: 'An incredible experience from start to finish. The attention to detail and professionalism was outstanding. I would highly recommend this service to anyone looking for top-quality work.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 3,
      name: 'Michael Chen',
      subtitle: '65 Reviews 10 Referrals - Silver Ambassador',
      date: '3 Months Ago',
      customerExperience: '92/100',
      review: 'A fantastic service that exceeded my expectations. The team was knowledgeable, friendly, and truly passionate about what they do. Im looking forward to working with them again.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    }
  ];
  const progressData = [
    { lable: 'Price vs Value / Business Operation', value: 87 },
    { lable: 'Delivery Time / Business Operation', value: 82 },
    { lable: 'Business Layout / Business Operation', value: 92 },
    { lable: 'Quality / Products / Services', value: 92 },
    { lable: 'Performance / Products / Services', value: 85 },
    { lable: 'Easy To Use / Products / Services', value: 54 },
    { lable: 'Durability / Products / Services', value: 55 },
    { lable: 'Expertise / Interaction Journey', value: 89 },
    { lable: 'Responsiveness  / Interaction Journey', value: 71 },
    { lable: 'Professionalism  / Interaction Journey', value: 61 },
    { lable: 'ExtraCare  / Interaction Journey', value: 61 }
  ];
  const yearData = [
    { factor: 'Price vs Value', satisfaction: 87 },
    { factor: 'Quality', satisfaction: 92 },
    { factor: 'Performance', satisfaction: 85 },
    { factor: 'Expertise', satisfaction: 89 },
    { factor: 'Responsiveness', satisfaction: 71 },
    { factor: 'Professionalism', satisfaction: 61 },
    { factor: 'Delivery Time', satisfaction: 82 },
    { factor: 'Business Layout', satisfaction: 92 },
    { factor: 'Easy To Use', satisfaction: 54 },
    { factor: 'Durability', satisfaction: 55 },
    { factor: 'ExtraCare', satisfaction: 61 }
  ];

  const handleteam =() =>
  {
    navigate('team');

  }

  const handleProducts =() =>
  {
navigate('products')
  }

  

  const PerformanceBar = ({ label, value, percentage }) => (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
      <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
      </div>
      <div className="flex items-center space-x-4 w-full sm:w-3/4">
        <div className="flex-grow bg-[#DC3DF7] h-3 rounded-full">
          <div 
            className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
          {value}/10
        </span>
      </div>
    </div>
  );

  
  return (
    <div
      className="w-full bg-[#4D6F8F] p-4 sm:p-8 space-y-4 sm:space-y-8 mt-20"
      style={{ fontFamily: "GeneralSansRegular" }}
    >
      <div className="border-2 sm:border-4 border-white rounded-xl overflow-hidden appearance-none">
        <div className="relative w-full h-48 sm:h-96 appearance-none">
          <video
            className="w-full h-full object-cover appearance-none"
            autoPlay
            muted
            playsInline
            loop
          >
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* Logo and Text Container */}
          <div className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 flex items-center space-x-2 sm:space-x-4">
            <img
              src={logo1}
              alt="Company Logo"
              className="h-10 sm:h-16 w-auto"
            />

            <div className="text-white w-32 sm:w-44">
              <h2 className="text-lg sm:text-2xl font-bold">
                Crown Casino Melborne
              </h2>
            </div>
          </div>
          <div className="absolute bottom-5 right-8">
            <button
              onClick={() => setIsFollowing(!isFollowing)}
              className={`px-3 py-2 rounded-lg transition-colors duration-300 ${
                isFollowing
                  ? "bg-[#B7BDCA] text-[#0D182E]"
                  : "bg-[#0D182E] text-white"
              }`}
              style={{ fontFamily: "GeneralSansMedium" }}
            >
              {isFollowing ? "Visited" : "Visit Us"}
            </button>
          </div>
        </div>
      </div>

      {/* QR Code Containers */}
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 sm:gap-8 mt-4 sm:mt-8">
        {/* Left QR Code Container */}
        <div className="flex flex-col items-center space-y-2 sm:space-y-4">
          <div className="w-35 h-35 sm:w-40 sm:h-40 bg-[#B7BDCA] rounded-xl sm:rounded-3xl flex items-center justify-center">
            <QRCode
              value="https://www.instagram.com/"
              size={80} // Adjusted for mobile
              logoImage={logo}
              logoWidth={30}
              logoHeight={25}
              logoPadding={5}
              logoPaddingStyle="square"
              logoOpacity={1}
              quietZone={8}
              fgColor="black"
              bgColor="#B7BDCA"
            />
          </div>
          <h2 className=" text-lg sm:text-3xl text-white font-[VarinoRegular]">
            Review 3%
          </h2>
          <h2 className="text-lg sm:text-3xl text-white font-[VarinoRegular]">
            {" "}
            Cash back
          </h2>
        </div>

        {/* Right QR Code Container */}
        <div className="flex flex-col items-center space-y-2 sm:space-y-4">
          <div className="w-35 h-35 sm:w-40 sm:h-40 bg-[#0D182E] rounded-xl sm:rounded-3xl flex items-center justify-center">
            <QRCode
              value="https://referral10.com"
              size={80}
              logoImage={logo}
              logoWidth={30}
              logoHeight={25}
              logoPadding={5}
              logoPaddingStyle="square"
              logoOpacity={1}
              quietZone={8}
              fgColor="#ffffff"
              bgColor="#0D182E"
            />
          </div>
          <h2 className="text-lg sm:text-3xl text-white font-[VarinoRegular]">
            Referral 3%
          </h2>
          <h2 className="text-lg sm:text-3xl text-white font-[VarinoRegular]">
            {" "}
            Cash back
          </h2>
        </div>
      </div>

      {/* Centered Paragraph */}
      <div className="text-center my-4 sm:my-8 px-4 sm:px-16">
        <p className="text-white text-base sm:text-xl">
          From acclaimed restaurants and award-winning hotels, to world-class
          table games and endless entertainment, Crown Rewards helps you to
          discover the world of Crown
        </p>
      </div>
      <div className="flex justify-center">
        <button
          onClick={handleProducts}
          className="px-8 py-3 mx-2 text-xl rounded-lg transition-all duration-300 font-[VerminViva] bg-[#0D182E] text-white border border-white/30 hover:bg-white/10"
        >
          Products
        </button>
        <button
          onClick={handleteam}
          className="px-8 py-3 mx-2 text-xl rounded-lg transition-all duration-300 font-[VerminViva] bg-[#0D182E] text-white border border-white/30 hover:bg-white/10"
        >
          Our Team
        </button>
      </div>

      <div className="w-full bg-transparent py-8 px-4 rounded-2xl">

        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Working Hours */}
          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Clock
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">
                Working Hours
              </h3>
              <p className="text-[#B7BDCA] text-base">
                Mon-Fri: 9:00 AM - 5:00 PM
                <br />
                Sat: 10:00 AM - 2:00 PM
                <br />
                Sun: Closed
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Utensils
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Category</h3>
              <p className="text-[#B7BDCA] text-base">
                Restaurant & Entertainment
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-4  p-6 rounded-xl">
            <Phone
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Phone</h3>
              <p className="text-[#B7BDCA] text-base">+61 3 9292 8888</p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Globe
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Website</h3>
              <p className="text-[#B7BDCA] text-base">
                www.crownmelbourne.com.au
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Instagram
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Instagram</h3>
              <p className="text-[#B7BDCA] text-base">@crownresorts</p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Facebook
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Facebook</h3>
              <p className="text-[#B7BDCA] text-base">Crown Melbourne</p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Mail
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Email</h3>
              <p className="text-[#B7BDCA] text-base">contact@crown.com.au</p>
            </div>
          </div>

          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <MapPin
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Location</h3>
              <p className="text-[#B7BDCA] text-base">
                Crown Towers, Australia
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-transparent p-6 rounded-xl">
            <Handshake
              color="#0D182E"
              className="bg-[#B7BDCA] rounded-full p-1"
              size={40}
            />
            <div>
              <h3 className="text-[#0D182E] text-xl font-bold">Work with Us</h3>
              <p className="text-[#B7BDCA] text-base">Join our dynamic team!</p>
            </div>
          </div>
        </div>
      </div>

      {/* Compact Full Width Container */}
      <div className="w-full bg-[#0D182E] py-12 px-4 rounded-2xl ">
        <div className="max-w-4xl mx-auto flex justify-between items-center">
          <div className="w-1/2 pr-6">
            <img
              src={leftImage}
              alt="Left Image"
              className="w-full h-auto mb-4"
            />
            <p className="text-left text-[#DC3DF7]  font-[VarinoRegular] text-xl sm:text-2xl md:text-3xl">
              Total Reviews
            </p>
          </div>

          {/* Right Section */}
          <div className="w-1/2 pl-6">
            <img
              src={rightImage}
              alt="Right Image"
              className="w-full h-auto mb-10 "
            />
            <p className="text-right text-[#DC3DF7] font-[VarinoRegular] text-xl sm:text-2xl md:text-3xl">
              Total Referrals
            </p>
          </div>
        </div>

        {/* Full Width Line */}
        <div className="w-full border-t border-white/30 my-6 max-w-4xl mx-auto"></div>

        {/* Text on Left and Right */}
        <div className="flex justify-between text-white max-w-4xl mx-auto">
          <p className="text-left text-3xl text-[#65D4B0] font-[VarinoRegular]">
            2001
          </p>
          <p className="text-right text-3xl text-[#65D4B0] font-[VarinoRegular]">
            1984
          </p>
        </div>
      </div>
      <div className="w-full p-2 rounded-lg bg-[#0D182E]">
  <h2 className="text-2xl font-['GeneralSansBold'] text-slate-100 mb-4 p-2">
    Brand Experience
  </h2>
  <div className="h-px w-full bg-slate-700 mb-4" />
  
  <div className="flex flex-col md:flex-row gap-4 p-4">
    <div className="w-full md:w-[70%]">
      {progressData.map((item, index) => (
        <div key={index} className="mb-4">
          <div className="flex justify-between mb-2">
            <span className="font-[GeneralSansMedium] text-white">{item.lable}</span>
            <span className="font-[GeneralSansMedium] text-white">{item.value}</span>
          </div>
          <div className="w-full bg-[#DC3DF7] rounded-full h-3">
            <div 
              className="bg-[#65D4B0] h-3 rounded-full" 
              style={{width: `${item.value}%`}}
            ></div>
          </div>
        </div>
      ))}
    </div>
    
    <div className="w-full md:w-1/3 flex items-center justify-center md:justify-end">
      <img 
        src={mapicon} 
        alt="Map" 
        className="w-full h-auto"
      />
    </div>
  </div>
</div>
      <div className="w-full bg-slate-900 py-12 px-8 rounded-2xl border-8 border-emerald-500">
      <h2 className="text-white text-3xl font-['GeneralSansBold'] mb-6 text-center lg:text-center">
              Business Performance Insights
            </h2>
  <div className="w-full mx-auto flex flex-col lg:flex-row gap-8">
    {/* Left Side - Radial Chart Section */}
    <div className="w-full lg:w-1/2">
      <div className="relative h-[400px]">
        {/* Logo near dial origin */}
        <div className="absolute top-1/2 left-1/2 pr-14 pb-4 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <img
            src={logo}
            alt="Company Logo"
            className="w-10 h-10 object-contain"
          />
        </div>

        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart 
            innerRadius="70%" 
            outerRadius="90%" 
            data={[currentPerformance]} 
            startAngle={180} 
            endAngle={0}
          >
            {/* Customer Experience Dial */}
            <RadialBar 
              minAngle={15} 
              background 
              clockWise 
              dataKey="value" 
              cornerRadius={14}
              fill="#DC3DF7"
              outerRadius="40%"
            />
            
            {/* Reviews Dial */}
            <RadialBar 
              minAngle={15} 
              background 
              clockWise 
              dataKey="value" 
              cornerRadius={14}
              fill="#3DE9DC"
            />
          </RadialBarChart>
        </ResponsiveContainer>
        
        {/* Value Labels inside radial bars */}
        <div className="absolute top-1/2 left-1/4 sm:pt-5 sm:pl-4 pl-20  pt-5 text-center">
          <div className="text-xs font-['GeneralSansBold'] text-[#DC3DF7]">
            Brand Experience <span className="text-white text-sm">{currentPerformance.value}</span>
          </div>
        </div>
        
        <div className="absolute top-1/2 right-1/4 sm:right-1/3 pt-5 pr-20 sm:pr-4 text-center">
          <div className="text-xs font-['GeneralSansBold'] text-[#3DE9DC]">
            Reviews <span className="text-white text-sm">{currentPerformance.value}</span>
          </div>
        </div>

        {/* Custom Needle */}
        <div 
          className="absolute top-36 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1 h-24 sm:h-28 bg-white origin-bottom"
          style={{
            transform: `translate(-50%, -50%) rotate(${270 + (currentPerformance.value * 180 / 100)}deg)`,
            boxShadow: '0 0 10px rgba(255,255,255,0.3)',
            transition: 'transform 1.5s ease-out'
          }}
        >
          <div 
            className="absolute bottom-0 left-1/2 -translate-x-1/2 w-4 h-4 bg-white border-2 border-[#DC3DF7] rounded-full"
            style={{
              transition: 'all 0.5s ease-out'
            }}
          />
        </div>
      </div>

      <div className="mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          {performanceData.map((perf) => (
            <button
              key={perf.name}
              onClick={() => setCurrentPerformance(perf)}
              className={`p-3 text-left rounded-lg transition-all duration-300 flex justify-between items-center shadow-md 
                ${currentPerformance.name === perf.name 
                  ? 'bg-gradient-to-r from-[#3DE9DC] to-[#3DE9DC]/80 text-[white] font-bold' 
                  : 'bg-[#1E2A44] text-slate-300 hover:bg-[#2C3E5A] hover:shadow-xl'
              }`}
            >
              <span className="text-base font-['GeneralSansMedium']">{perf.name}</span>
              <span className="text-sm font-['GeneralSansBold'] bg-[#0D182E]/50 px-2 py-1 rounded-full">{perf.reviews}</span>
            </button>
          ))}
        </div>
      </div>
    </div>

    {/* Right Side - Bar Chart Section */}
    <div className="w-full lg:w-1/2">
      <div className="flex flex-col h-full">
        {/* Chart Container */}
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData[selectedPeriod]}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
             <CartesianGrid 
                horizontal={true} 
                vertical={false} 
                stroke="rgba(255,255,255,0.1)" 
              />
              <XAxis 
                dataKey="month" 
                tick={{ fill: 'white' }} 
                axisLine={{ stroke: 'white' }}
              />
              <YAxis 
                tick={{ fill: 'white' }} 
                axisLine={{ stroke: 'white' }}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: '#0D182E', 
                  color: 'white',
                  border: '1px solid white'
                }} 
              />
              <Legend 
                wrapperStyle={{ color: 'white' }} 
                iconType="circle"
              />
              <Bar 
                dataKey="reviews" 
                fill="#DC3DF7" 
                name="Reviews" 
                stackId="a"
                barSize={35}
              />
              <Bar 
                dataKey="referrals" 
                fill="#3DE9F7" 
                name="Referrals" 
                stackId="a"
                barSize={35}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>


        {/* Period Selection Tabs - Now properly aligned below the bar chart */}
        <div className="mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            {['12 months', '30 days', '7 days'].map((period) => (
              <button
                key={period}
                onClick={() => setSelectedPeriod(period)}
                className={`py-3 text-white text-lg rounded-lg transition-all duration-300 ${
                  selectedPeriod === period 
                    ? 'bg-purple-600 font-bold' 
                    : 'bg-slate-900 hover:bg-slate-800'
                }`}
              >
                {period}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

            <section className=" mx-auto bg-[#0D182E] rounded-xl shadow-lg p-6 m-4 ">
            {/* Header Section */}
            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
              <div className="flex items-center">
                <div className="flex items-center">
                  <img 
                    src={reviewsIcon} 
                    alt="Reviews" 
                    className="w-8 h-8 mr-3" 
                  />
                  <h2 className="text-2xl font-['GeneralSansBold'] text-white">
                    Existing Reviews
                  </h2>
                </div>
              </div>
              
              {/* Navigation Buttons */}
              <div className="flex items-center gap-3">
                <button 
                  onClick={() => setCurrentReviewIndex(Math.max(0, currentReviewIndex - 1))}
                  className="p-3 rounded-full bg-[#527DE6] text-white hover:bg-[#4267c7] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={currentReviewIndex === 0}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    className="w-5 h-5"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M15 19l-7-7 7-7" 
                    />
                  </svg>
                </button>
                
                <div className="bg-[#1a2942] px-4 py-2 rounded-lg">
                  <span className="text-white font-['GeneralSansRegular']">
                    {currentReviewIndex + 1} / {reviewsData.length}
                  </span>
                </div>
                
                <button 
                  onClick={() => setCurrentReviewIndex(Math.min(reviewsData.length - 1, currentReviewIndex + 1))}
                  className="p-3 rounded-full bg-[#527DE6] text-white hover:bg-[#4267c7] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={currentReviewIndex === reviewsData.length - 1}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    className="w-5 h-5"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M9 5l7 7-7 7" 
                    />
                  </svg>
                </button>
              </div>
            </div>
      
            {/* Review Content */}
            <div className="mt-4">
              <ReviewSectionn 
                review={reviewsData[currentReviewIndex]}
                currentReviewIndex={currentReviewIndex}
                setCurrentReviewIndex={setCurrentReviewIndex}
                reviewsData={reviewsData}
              />
            </div>
          </section>
    </div>
  );
};

export default ReviewSection;