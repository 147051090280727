import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import image from "./assets/images/imagetest1.png";
import image1 from "./assets/images/imagetest2.png";
import image2 from "./assets/images/illustration.png"

const BusinessSendLink = () => {
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const navigate = useNavigate();

  const handleSendEmail = () => {
    console.log("Sending invite to:", email);
    setIsModalOpen(true); // Show modal on send button click
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <div className="min-h-screen pt-20 px-4 sm:px-6 lg:px-8 relative">
      {/* Full-width rounded section */}
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            BUSINESS SEND LINK
          </h1>
        </div>
      </div>

      <div className="max-w-full bg-[#0D182E] rounded-lg shadow-md p-6 sm:p-8 grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Section */}
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-2xl font-['GeneralSansBold'] text-white mb-6 text-center">
            Scan QR Code
          </h2>

          {/* QR Code Image */}
          <img
            src={image}
            alt="QR Code"
            className="w-44 h-44 object-cover rounded-lg mb-6"
          />

          {/* Scan Button */}
          <button
            className="w-full bg-[#B7BDCA] text-white py-3 rounded-lg hover:bg-[#B7BDCA]/90 transition-colors"
            onClick={() => {
              /* Add scan logic */
            }}
          >
            Scan
          </button>
        </div>

        {/* Right Section */}
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-2xl font-['GeneralSansBold'] text-white mb-6 text-center">
            Invite Via Email
          </h2>

          {/* Email Invite Image */}
          <img
            src={image1}
            alt="Email Invite"
            className="w-34 h-34 object-cover rounded-lg mb-20"
          />

          {/* Email Input */}
          <input
            type="email"
            placeholder="Enter email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-3 rounded-lg mb-4 bg-white/10 text-white border border-white/20 focus:outline-none focus:ring-2 focus:ring-[#B7BDCA]"
          />

          {/* Send Button */}
          <button
            className="w-full bg-[#B7BDCA] text-white py-3 rounded-lg hover:bg-[#B7BDCA]/90 transition-colors"
            onClick={handleSendEmail}
            disabled={!email}
          >
            Send
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <div
            className="bg-[#B7BDCA] rounded-lg p-8 w-full max-w-md text-center shadow-lg"
          >
            <h2
              className="text-2xl font-['GeneralSansBold'] text-black mb-6"
            >
              Invitation Sent
            </h2>
            <img
              src={image2}
              alt="Confirmation"
              className="w-40 h-40 mx-auto mb-6"
            />
            <p className="text-[#182B53] text-base mb-6 font-[GeneralSansRegular]">
            Your review invitation has been sent to Samuel Weeks Successfully           
             </p>
            <button
              className="w-full bg-[#0D182E] text-white py-3 rounded-full transition-colors font-[GeneralSansRegular]"
              onClick={closeModal}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessSendLink;
