import React, { useState } from 'react';
import { Search, ArrowUpDown, Calendar, Trash2, X } from 'lucide-react';
import product1 from "./assets/images/noburestaurent.png";
import product2 from "./assets/images/noburestaurent.png";
import product3 from "./assets/images/noburestaurent.png";
import logo1 from "./assets/images/loogfinal.png"
import logo2 from "./assets/images/avatar2.png"

const MyShopList = () => {
  // Initial shop list data
    const [selectedThermometer, setSelectedThermometer] = useState(null);
    const initialShopListData = [
      {
        id: 1,
        image: product1,
        name: "Premium Tattoo Ink Set",
        businessName: "Business1",
        category:"Eid 1",
        businessLogo:logo1,
        location:"New York , NY",
        price: 129.99,
        reviewCount: 245,
        rating: 95,
        dateAdded: "2024-01-15",
        stockStatus: "In Stock",
        performanceScore: 85,  // Added performance score
        joinDate: "2023-06-15", // Added for stats calculation
        amountSpent: 5000,      // Added for stats calculation
        referredCustomers: ['customer1', 'customer2'], // Added for stats calculation
        referrals: 3            // Added for stats calculation
      },
      {
        id: 2,
        image: product2,
        name: "Artist Pro Tattoo Machine",
        businessName: "Business2",
        category: "Eid 2",
        location:"New York , NY",
        businessLogo:logo1,
        price: 349.99,
        reviewCount: 188,
        rating: 88,
        dateAdded: "2024-02-20",
        stockStatus: "Low Stock",
        performanceScore: 65,  // Added performance score
        joinDate: "2023-09-15", // Added for stats calculation
        amountSpent: 3500,      // Added for stats calculation
        referredCustomers: ['customer3'], // Added for stats calculation
        referrals: 1            // Added for stats calculation
      },
      {
        id: 3,
        image: product3,
        name: "Healing Tattoo Care Kit",
        businessName: "Business3",
        category: "Independance Day",
        location:"New York , NY",
        businessLogo:logo1,
        price: 49.99,
        reviewCount: 324,
        rating: 92,
        dateAdded: "2024-03-10",
        stockStatus: "In Stock",
        performanceScore: 75,  // Added performance score
        joinDate: "2023-08-15", // Added for stats calculation
        amountSpent: 4200,      // Added for stats calculation
        referredCustomers: ['customer4', 'customer5', 'customer6'], // Added for stats calculation
        referrals: 6            // Added for stats calculation
      }
    ];

  // State management
  const [shopListData, setShopListData] = useState(initialShopListData);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('all');

  // Delete handler
  const handleDelete = (id) => {
    const updatedList = shopListData.filter(item => item.id !== id);
    setShopListData(updatedList);
  };

  const calculateStats = (customer) => {
    // Calculate Duration Average (Days)
    const durationAverageInDays = customer.duration 
      ? customer.duration 
      : Math.ceil((new Date() - new Date(customer.joinDate)) / (1000 * 60 * 60 * 24));
  
    // Calculate Daily Cost per Duration
    const dailyCostPerDuration = customer.amountSpent 
      ? (customer.amountSpent / durationAverageInDays).toFixed(2) 
      : (0).toFixed(2);
  
    // Calculate Average Price from the existing data
    const averagePrice = customer.price ? customer.price : 0;
  
    return {
      confidencePercentage: customer.performanceScore,
      durationAverageInDays: durationAverageInDays,
      dailyCostPerDuration: dailyCostPerDuration,
      averagePrice: averagePrice.toFixed(2)
    };
  };
  // Date filtering helper function
  const isWithinDateRange = (dateString, filter) => {
    const date = new Date(dateString);
    const now = new Date();
    const oneWeek = 7 * 24 * 60 * 60 * 1000;
    const oneMonth = 30 * 24 * 60 * 60 * 1000;
    const oneYear = 365 * 24 * 60 * 60 * 1000;

    switch (filter) {
      case 'week':
        return (now - date) <= oneWeek;
      case 'month':
        return (now - date) <= oneMonth;
      case 'year':
        return (now - date) <= oneYear;
      default:
        return true;
    }
  };

  // Sorting handler
  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...shopListData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'price-high':
        sortedData.sort((a, b) => b.price - a.price);
        break;
      case 'price-low':
        sortedData.sort((a, b) => a.price - b.price);
        break;
      case 'rating-high':
        sortedData.sort((a, b) => b.rating - a.rating);
        break;
      case 'rating-low':
        sortedData.sort((a, b) => a.rating - b.rating);
        break;
      default:
        break;
    }

    setShopListData(sortedData);
  };

  // Filter products
  const filteredItems = shopListData.filter(item => 
    item.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    isWithinDateRange(item.dateAdded, dateFilter)
  );

  const ThermometerGauge = ({ value, maxValue = 100, height = 120, onClick }) => {
    const percentage = (value / maxValue) * 100;
    const bulbSize = Math.max(20, height * 0.2);
    const stemWidth = Math.max(6, bulbSize * 0.3);
    
    // New color scheme using Tailwind pink and purple colors
    const getGradientColors = (percentage) => {
      const baseColors = {
        low: { from: '#ec4899', to: '#9333ea' },      // pink-500 to purple-600
        mid: { from: '#ec4899', to: '#9333ea' },      // pink-500 to purple-600
        high: { from: '#ec4899', to: '#9333ea' }      // pink-500 to purple-600
      };
      
      return baseColors[percentage < 30 ? 'low' : percentage < 70 ? 'mid' : 'high'];
    };
  
    const colors = getGradientColors(percentage);
    
    return (
      <div 
        className="flex flex-col items-center gap-2 cursor-pointer group mt-5 mr-60 md:mr-20 sm:mr-20 " 
        onClick={onClick}
        style={{ height: height + bulbSize / 2 }}
      >
        {/* Value label */}
        <span className="text-white text-sm transition-opacity group-hover:opacity-100 opacity-80 -mt-4  font-['GeneralSansMedium']">
          {value}%
        </span>
        
        {/* Thermometer */}
        <div className="relative" style={{ height: height }}>
          {/* Stem */}
          <div 
            className="absolute left-1/2 transform -translate-x-1/2 bg-gray-700 rounded-t-lg"
            style={{ 
              width: stemWidth,
              height: height - bulbSize / 2,
              bottom: bulbSize / 2
            }}
          >
            {/* Liquid in stem */}
            <div 
              className="absolute bottom-0 w-full rounded-t-lg transition-all duration-500 ease-in-out"
              style={{ 
                height: `${percentage}%`,
                background: `linear-gradient(to top, ${colors.from}, ${colors.to})`,
                boxShadow: 'inset 0 2px 4px rgba(255,255,255,0.3)'
              }}
            />
            
            {/* Scale marks */}
            {[ 25, 50, 75, 100].map((mark) => (
              <div 
                key={mark}
                className="absolute right-full mr-1 w-2 h-px bg-gray-500"
                style={{ bottom: `${mark}%` }}
              />
            ))}
          </div>
          
          {/* Bulb */}
          <div 
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 rounded-full bg-gradient-to-r from-pink-500 to-purple-600"
            style={{ 
              width: bulbSize,
              height: bulbSize,
              boxShadow: `
                inset -2px -2px 6px rgba(0,0,0,0.3),
                inset 2px 2px 6px rgba(255,255,255,0.3),
                0 0 10px rgba(0,0,0,0.3)
              `
            }}
          >
            {/* Bulb shine effect */}
            <div 
              className="absolute w-1/3 h-1/3 bg-white rounded-full opacity-50"
              style={{ 
                top: '20%',
                left: '20%',
                filter: 'blur(1px)'
              }}
            />
          </div>
        </div>
      </div>
    );
  };
    
  const ThermometerModal = ({ customer }) => {
    const stats = calculateStats(customer);
    
    return (
      <div className="text-white">
        <h2 className="text-xl font-['GeneralSansMedium'] mb-6">
          Purchaser Confidence Score
        </h2>
        
        <div className="flex gap-8 items-center">
          {/* Large Thermometer with scale */}
          <div className="flex items-center gap-4">
            {/* Scale numbers */}
            <div className="flex flex-col justify-between h-[200px] text-sm text-[#64718C]  font-['GeneralSansMedium']">
              {[100, 75, 50, 25, 0].map((num) => (
                <span key={num}>{num}</span>
              ))}
            </div>
            
            <ThermometerGauge 
              value={customer.performanceScore} 
              height={200}
            />
          </div>
  
          {/* Stats panel */}
          <div className="flex-1">
            <div className="grid gap-4">
              <div className="bg-[#1A2E56] p-4 rounded-lg">
                <p className="text-[#64718C] mb-1  font-['GeneralSansMedium']">Confidence Score</p>
                <p className="text-lg  font-['GeneralSansMedium']" style={{ color: '#ec4899' }}>{stats.confidencePercentage}%</p>
              </div>
              
              <div className="bg-[#1A2E56] p-4 rounded-lg  font-['GeneralSansMedium']">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-[#64718C]">Duration Average (Days)</p>
                    <p className="text-white">{stats.durationAverageInDays}</p>
                  </div>
                  <div>
                    <p className="text-sm text-[#64718C]">Daily Cost per Duration</p>
                    <p className="text-white">${stats.dailyCostPerDuration}</p>
                  </div>
                  <div>
                    <p className="text-sm text-[#64718C]">Average Price</p>
                    <p className="text-white">${stats.averagePrice}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
    
    
    const Modal = ({ isOpen, onClose, children }) => {
      if (!isOpen) return null;
    
      return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-[#0D182E] rounded-lg p-6 max-w-2xl w-full mx-4 relative">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-white hover:text-gray-300"
            >
              <X size={24} />
            </button>
            {children}
          </div>
        </div>
      );
    };
  
  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Name Search */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Product
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10 font-['GeneralSansRegular']"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Options */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="price-high">Price (High-Low)</option>
              <option value="price-low">Price (Low-High)</option>
              <option value="rating-high">Rating (High-Low)</option>
              <option value="rating-low">Rating (Low-High)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none font-['GeneralSansRegular']"
            >
              <option value="all">All Time</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="year">Last Year</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Shop List Table */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">My Shop List</h2>
        <table className="min-w-full">
          <thead className="hidden sm:table-header-group">
            <tr className="bg-[#1a2942]">
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Product</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Category</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Purchaser Confidence Meter</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Business</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Price</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Reviews</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Rating</th>
              <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Delete From List</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {filteredItems.map((item) => (
              <tr key={item.id} className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
                <td className="px-4 sm:px-6 py-4 flex items-center">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 rounded-lg overflow-hidden">
                      <img src={item.image} alt={item.name} className="w-full h-full object-cover" />
                    </div>
                    <div>
                      <p className="font-['GeneralSansRegular'] text-white">{item.name}</p>
                    </div>
                  </div>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-white">{item.category}</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                <ThermometerGauge 
                        value={item.performanceScore} 
                        height={70}
                        onClick={() => setSelectedThermometer(item)}
                      />
                 </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-lg overflow-hidden">
                        <img 
                          src={item.businessLogo} 
                         
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <span className="font-['GeneralSansRegular'] text-white">{item.businessName}</span>
                    </div>
                  
                  <p className="font-['GeneralSansRegular'] ml-10 text-xs text-white">{item.location}</p>
                  
                  
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-[#3DE9DC]">${item.price}</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <span className="font-['GeneralSansRegular'] text-white">{item.reviewCount} <span className="sm:hidden">Reviews</span></span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <div className="w-full bg-[#DC3DF7] rounded-full h-2">
                    <div 
                      className="bg-[#3DE9DC] h-2 rounded-full" 
                      style={{ width: `${item.rating}%` }}
                    ></div>
                  </div>
                  <span className="text-white text-sm ml-2 font-['GeneralSansRegular']">{item.rating}%</span>
                </td>
                <td className="px-4 sm:px-6 py-2 sm:py-4">
                  <button
                    onClick={() => handleDelete(item.id)}
                    className="text-red-500 hover:text-red-400 transition-colors"
                  >
                    <Trash2 size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* No Results Message */}
        {filteredItems.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
            No items found in your shop list matching the selected filters.
          </div>
        )}
      </div>
    {/* Performance Score Modal */}
    <Modal 
    isOpen={selectedThermometer !== null} 
    onClose={() => setSelectedThermometer(null)}
  >
    {selectedThermometer && (
      <ThermometerModal customer={selectedThermometer} />
    )}
  </Modal>
      </div>
    );
  };

export default MyShopList;