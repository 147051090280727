import React from 'react';
import { ResponsiveContainer, RadialBarChart, RadialBar } from 'recharts';
import logo from "./assets/images/loogfinal.png"

const SmallDial = ({ currentPerformance = { value: 100 } }) => {
  return (

      

        <div className="w-full lg:w-1/2 h-[130px] relative mb-3 lg:mb-0 sm:-ml-20">
          {/* Logo near dial origin */}
          <div className="absolute top-1/2 left-1/2 pr-7 pb-2 transform -translate-x-1/2 -translate-y-1/2 z-10">
            <img
              src={logo}
              alt="Company Logo"
              className="w-6 h-6 object-contain"
            />
          </div>

          <ResponsiveContainer width="100%" height="100%">
            <RadialBarChart 
              innerRadius="70%" 
              outerRadius="90%" 
              data={[currentPerformance]} 
              startAngle={180} 
              endAngle={0}
            >
              <RadialBar 
                minAngle={15} 
                background 
                clockWise 
                dataKey="value" 
                cornerRadius={7}
                fill="#DC3DF7"
                outerRadius="40%"
              />
              
              <RadialBar 
                minAngle={15} 
                background 
                clockWise 
                dataKey="value" 
                cornerRadius={7}
                fill="#3DE9DC"
              />
            </RadialBarChart>
          </ResponsiveContainer>
          
          {/* Value Labels */}
          <div className="absolute top-1/2 left-10 sm:left-1/5 sm:pt-3 pl-6  pt-3 text-center">
            <div className="text-xs font-['GeneralSansBold'] text-[#DC3DF7]">
              Brand Experience <span className="text-white">{currentPerformance.value}% </span>
            </div>
          </div>
          
          <div className="absolute top-1/2 right-10 sm:right-20 pl-14 sm:pl-4 pt-3 text-center">
            <div className="text-xs font-['GeneralSansBold'] text-[#3DE9DC]">
                Reviews <span className="text-white">{currentPerformance.value}</span>
            </div>
          </div>

          {/* Custom Needle */}
          <div 
            className="absolute top-[4.5rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-0.5 h-9 bg-white origin-bottom"
            style={{
              transform: `translate(-115%, -115%) rotate(${270 + (currentPerformance.value * 180 / 100)}deg)`,
              boxShadow: '0 0 5px rgba(255,255,255,0.3)',
              transition: 'transform 1.5s ease-out' // Add smooth transition
            }}
          >
            <div 
              className="absolute bottom-0 left-1/2 -translate-x-1/2 w-2 h-2 bg-white border border-[#DC3DF7] rounded-full"
            />
          </div>
        </div>
  );
};

export default SmallDial;