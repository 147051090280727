import React, { useState, useEffect } from 'react';
import { Bookmark, Users, DollarSign, Eye, ShoppingCart, Clock, Send, Share, Zap, File, UserCheck2, Import } from 'lucide-react';
import profile from "./assets/images/profile.png";
import logo from "./assets/images/loogfinal.png";
import logo2 from "./assets/images/logoauthentic.png";
import SmallDial from './SmallDial';
import reviewsIcon from "./assets/images/Existing Review.png";
import image1 from "./assets/images/teammember3.jpeg"
import image2 from "./assets/images/bimage.png"
import image3  from "./assets/images/image_lion.png"
import image4 from "./assets/images/teammember1.jpeg"

const StatCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-[#0D182E] p-4 sm:p-6 rounded-lg h-full">
    <div className="flex items-center gap-4 mb-2">
      <div className="p-2 sm:p-3 bg-transparent border-2 rounded-full">
        <Icon className="w-4 h-4 sm:w-6 sm:h-6 text-white" />
      </div>
      <h3 className="text-sm sm:text-lg font-['GeneralSansSemibold'] text-slate-100">{title}</h3>
    </div>
    <p className="text-xl sm:text-3xl font-['GeneralSansMedium'] text-[#DC3DF7] mb-1">{value}</p>
  </div>
);

const ReviewImageCarousel = ({ images, selectedImage, onImageSelect }) => {
  return (
    <div className="w-full">
      {/* Main Image Display */}
      <div className="w-full h-64 md:h-96 mb-4 rounded-lg overflow-hidden  flex items-center justify-center">
        <img 
          src={images[selectedImage]} 
          alt={`Review ${selectedImage + 1}`} 
          className="max-w-full max-h-full w-auto h-auto object-contain"
        />
      </div>
      
      {/* Thumbnail Strip */}
      <div className="flex gap-2 overflow-x-auto pb-2 justify-center">
        {images.map((img, idx) => (
          <button 
            key={idx}
            onClick={() => onImageSelect(idx)}
            className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden border-2 bg-[#1a2942] flex items-center justify-center
              ${selectedImage === idx ? 'border-[#DC3DF7]' : 'border-transparent'}`}
          >
            <img 
              src={img} 
              alt={`Thumbnail ${idx + 1}`} 
              className="max-w-full max-h-full w-auto h-auto object-contain"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

const PerformanceBar = ({ label, value, percentage }) => (
  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
    <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
    </div>
    <div className="flex items-center space-x-4 w-full sm:w-3/4">
      <div className="flex-grow bg-[#DC3DF7] h-3 rounded-full">
        <div 
          className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
        {value}/10
      </span>
    </div>
  </div>
);

const ReviewSection = ({ review, currentReviewIndex, setCurrentReviewIndex, reviewsData }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  

  const reviewImages = [
    image1,
    image2,
    image3,
    image4
  ];

  return (
    <div className=" rounded-lg shadow-md p-6">
      {/* Row 1: Split into two columns */}
      <div className="flex flex-col lg:flex-row gap-6 mb-6">
        {/* Left Column: Customer Details */}
        <div className="w-full lg:w-1/2">
          <div className="flex items-start space-x-4 mb-6">
            <div className="w-16 h-16 rounded-full overflow-hidden flex-shrink-0">
              <img src={profile} alt="Profile" className="w-full h-full object-cover" />
            </div>
            <div>
              <h3 className="font-['GeneralSansBold'] text-white text-xl mb-1">{review.name}</h3>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm mb-2">{review.subtitle}</p>
              <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">{review.date}</p>
            </div>
          </div>




          <div className="mb-4">
            <h4 className="text-white font-['GeneralSansBold'] text-lg mb-2">
              Customer Experience: {review.customerExperience}
            </h4>
            <p className="font-['GeneralSansRegular'] text-gray-300 text-base">
              {review.review}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row   sm:items-center mb-6 space-y-4 sm:space-y-0 -ml-10 lg:ml-10">
          <img
                      src={logo2}
                      alt="Authentic"
                      className="w-28 sm:w-36 h-8 sm:h-10 ml-10 lg:-ml-10 mr-40"
                    />
                  <SmallDial currentPerformance={{ value:70 }}  />

                  </div>
        </div>

        {/* Right Column: Image Carousel */}
        <div className="w-full lg:w-1/2">
          <ReviewImageCarousel 
            images={reviewImages}
            selectedImage={selectedImage}
            onImageSelect={setSelectedImage}
          />
        </div>
      </div>

      {/* Row 2: Performance Bars */}
      <div className="mt-6">
        <h4 className="text-white font-['GeneralSansBold'] text-lg mb-4">Performance Metrics</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
          {review.performanceBars.map((bar, index) => (
            <PerformanceBar 
              key={index}
              label={bar.label}
              value={bar.value}
              percentage={bar.percentage}
            />
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-center gap-4 mt-6">
        <button 
          className="px-6 py-2 rounded-lg font-['GeneralSansBold'] text-base bg-[#B7BDCA] text-[#0D182E]"
        >
          Respond    
        </button>
        <button 
          className="px-6 py-2 rounded-lg font-['GeneralSansBold'] text-base bg-[#B7BDCA] text-[#0D182E]"
        >
          Report    
        </button>
      </div>

      {/* Navigation Dots */}
      <div className="flex justify-center mt-6 space-x-2">
        {reviewsData.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentReviewIndex(index)}
            className={`w-3 h-3 rounded-full transition-all duration-300
              ${currentReviewIndex === index ? 'bg-[#527DE6]' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};

const CustomerRow = ({ name, email, image, phone, stage }) => (
  <tr className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
    <td className="px-4 sm:px-6 py-4 flex items-center">
      <div className="flex items-center space-x-4">
        <div className="w-10 h-10 rounded-full overflow-hidden">
          <img src={image} alt={name} className="w-full h-full object-cover" />
        </div>
        <div>
          <p className="font-['GeneralSansRegular'] text-white">{name}</p>
          <p className="font-['GeneralSansRegular'] text-gray-400 text-sm">{email}</p>
          <p className="font-['GeneralSansRegular'] text-gray-400 text-sm">{phone}</p>
        </div>
      </div>
    </td>
    <td className="px-4 sm:px-6 py-2 sm:py-4 font-['GeneralSansRegular'] text-white">{stage}</td>
    <td className="px-4 sm:px-6 py-2 sm:py-4">
      <span className="px-2 py-1 text-xs font-['GeneralSansMedium'] rounded-full bg-[#65D4B0] text-[#0D182E]">
        Completed
      </span>
    </td>
    <td className="px-4 sm:px-6 py-2 sm:py-4">
      <button className="w-full sm:w-auto flex items-center justify-center space-x-2 px-4 py-2 border border-white rounded-lg text-white bg-transparent hover:bg-white/10 transition-colors">
        <Send size={16} />
        <span className="font-['GeneralSansMedium']">Contact</span>
      </button>
    </td>
  </tr>
);

const BusinessHome = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [businessData, setBusinessData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch business data on component mount
  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        const cachedData = localStorage.getItem('businessData');
        // Check if we have cached data and it's less than 1 hour old
        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
          if (Date.now() - timestamp < oneHour) {
            console.log('Using cached business data');
            setBusinessData(data);
            setLoading(false);
            return;
          }
        }

        // If no valid cached data, make the API call
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzQxNjIzNDU1LCJpYXQiOjE3NDE1OTQ2NTUsImp0aSI6IjBhZmY2ZGFmNjJjMzQ2ZDc4OTNjZTY4MDY0YWQwMmEwIiwidXNlcl9pZCI6MTl9.hiOIjK8b7La0bzrh5ECZnLWqgKPdVFVwC6zTz5kmzlg");
        
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
        
        const response = await fetch("https://superadmin.reviewpay.com.au/reviewpayrole_api/get_business_detail", requestOptions);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const result = await response.json();
        
        // Store data with timestamp in localStorage
        localStorage.setItem('businessData', JSON.stringify({
          data: result,
          timestamp: Date.now()
        }));
        
        setBusinessData(result);
        console.log('Business data fetched and stored:', result);
      } catch (error) {
        console.error('Error fetching business data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessData();
  }, []);

  const reviewsData = [
    {
      id: 1,
      name: 'Floyd Miles',
      subtitle: '147 Reviews 23 Referrals - Brand Ambassador',
      date: '1 Month Ago',
      customerExperience: '99/100',
      review: 'First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking. However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      subtitle: '82 Reviews 15 Referrals - Bronze Ambassador',
      date: '2 Months Ago',
      customerExperience: '95/100',
      review: 'An incredible experience from start to finish. The attention to detail and professionalism was outstanding. I would highly recommend this service to anyone looking for top-quality work.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    },
    {
      id: 3,
      name: 'Michael Chen',
      subtitle: '65 Reviews 10 Referrals - Silver Ambassador',
      date: '3 Months Ago',
      customerExperience: '92/100',
      review: 'A fantastic service that exceeded my expectations. The team was knowledgeable, friendly, and truly passionate about what they do. Im looking forward to working with them again.',
      performanceBars: [
        { label: 'Price vs Value / Business Operation',value:'8.7', percentage: 87 },
        { label: 'Delivery Time / Business Operation',value:'8.2', percentage: 82 },
        { label: 'Business Layout / Business Operation',value:'9.2', percentage: 92 },
        { label: 'Quality / Products / Services', value:'9.2',percentage: 92 },
        { label: 'Performance / Products / Services',value:'8.5', percentage: 85 },
        { label: 'Easy To Use / Products / Services',value:'5.4', percentage: 54 },
        { label: 'Durability / Products / Services', value:'5.5',percentage: 55 },
        { label: 'Expertise / Interaction Journey', value:'8.9',percentage: 89 },
        { label: 'Responsiveness  / Interaction Journey',value:'7.1', percentage: 71 },
        { label: 'Professionalism  / Interaction Journey',value:'6.1', percentage: 61 },
        { label: 'ExtraCare  / Interaction Journey',value:'6.1', percentage: 61 }
      ]
    }
  ];

  // Display loading state while fetching data
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-[#0D182E]">
        <div className="text-white text-xl">Loading business data...</div>
      </div>
    );
  }

  // Display error message if fetch failed
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-[#0D182E]">
        <div className="text-red-500 text-xl">
          Error loading data: {error}<br />
          <button 
            onClick={() => window.location.reload()} 
            className="mt-4 px-4 py-2 bg-[#527DE6] rounded-lg text-white"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
       {/* Stats Grid */}
       <div className="bg-[#65D4B0] m-6 p-5 mt-20 rounded-xl">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-[#0D182E] mb-2">Analytics Overview</h2>
        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-6 rounded-2xl'>
        <StatCard 
              icon={Bookmark}
              title="Reviews"
              value="85"
              description="Increase in monthly active users"
            />
            <StatCard 
              icon={Users}
              title="Refferals"
              value="2,345"
              description="Users active in the last 30 days"
            />
            <StatCard 
              icon={DollarSign}
              title="Revenue"
              value="$12,345"
              description="Total revenue this month"
            />
            <StatCard 
              icon={Eye}
              title="Review Pay Profile Visits"
              value="110"
              description="Average rating from users"
            />
            <StatCard 
              icon={ShoppingCart}
              title="My Shop List"
              value="1,234"
              description="Total orders this month"
            />
            <StatCard 
              icon={Clock}
              title="Days Since SignUp"
              value="111"
              description="User engagement rate"
            />
            <StatCard 
              icon={Share}
              title="Business Web Page Clicks"
              value="58"
              description="User engagement rate"
            />
            <StatCard 
              icon={File}
              title="Expenses"
              value="$1000"
              description="User engagement rate"
            />
             <StatCard 
              icon={UserCheck2}
              title="Users Place to Visit"
              value="42"
              description="People who follow your business"
            />
        </div>
      </div>

    

      <section className=" mx-auto bg-[#0D182E] rounded-xl shadow-lg p-6 m-4 md:m-8">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
        <div className="flex items-center">
          <div className="flex items-center">
            <img 
              src={reviewsIcon} 
              alt="Reviews" 
              className="w-8 h-8 mr-3" 
            />
            <h2 className="text-2xl font-['GeneralSansBold'] text-white">
              Latest Reviews
            </h2>
          </div>
        </div>
        
        {/* Navigation Buttons */}
        <div className="flex items-center gap-3">
          <button 
            onClick={() => setCurrentReviewIndex(Math.max(0, currentReviewIndex - 1))}
            className="p-3 rounded-full bg-[#527DE6] text-white hover:bg-[#4267c7] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={currentReviewIndex === 0}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              className="w-5 h-5"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M15 19l-7-7 7-7" 
              />
            </svg>
          </button>
          
          <div className="bg-[#1a2942] px-4 py-2 rounded-lg">
            <span className="text-white font-['GeneralSansRegular']">
              {currentReviewIndex + 1} / {reviewsData.length}
            </span>
          </div>
          
          <button 
            onClick={() => setCurrentReviewIndex(Math.min(reviewsData.length - 1, currentReviewIndex + 1))}
            className="p-3 rounded-full bg-[#527DE6] text-white hover:bg-[#4267c7] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={currentReviewIndex === reviewsData.length - 1}
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              className="w-5 h-5"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9 5l7 7-7 7" 
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Review Content */}
      <div className="mt-4">
        <ReviewSection 
          review={reviewsData[currentReviewIndex]}
          currentReviewIndex={currentReviewIndex}
          setCurrentReviewIndex={setCurrentReviewIndex}
          reviewsData={reviewsData}
        />
      </div>
    </section>


      {/* Latest Customers Section */}
      <section className="bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6 m-6">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">Latest Customers</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="bg-[#1a2942]">
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Customer</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Stage</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Status</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              <CustomerRow 
                name="John Doe"
                email="john.doe@example.com"
                phone="03364162163"
                stage="Review"
                image={profile}
              />
              <CustomerRow 
                name="Jane Smith"
                email="jane.smith@example.com"
                phone="03364162163"
                 stage="Referral"
                image={profile}
              />
              <CustomerRow 
                name="Mike Johnson"
                email="mike.johnson@example.com"
                phone="03364162163"
                 stage="Feedback"
                image={profile}
              />
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default BusinessHome;