import React, { useState } from 'react';
import ProfileImage from './assets/images/profile.png';
import ReviewIcon from './assets/images/review.png';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, Search, Filter, Calendar, ArrowUpDown, X } from 'lucide-react';






const CustomersComponent = () => {
  const navigate = useNavigate();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openReferralsIndex, setOpenReferralsIndex] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [selectedThermometer, setSelectedThermometer] = useState(null);

  // Enhanced customer data with performance score
  const initialCustomerData = [
    { 
      backgroundColor: 'bg-[#527DE6]', 
      name: 'Kat Larsson', 
      email: 'kat@contoso.org', 
      phone: '(469) 532 2382',
      location: 'New York, NY',
      amountSpent: 2500,
      reviews: 15,
      referrals: 8,
      reviewCashbackOutstanding: 1,
      referralCashbackOutstanding: 0,
      performanceScore: 85,
      referredCustomers: [
        { name: 'John Doe', email: 'john@example.com', date: '2024-02-15' },
        { name: 'Jane Smith', email: 'jane@example.com', date: '2024-03-01' }
      ],
      joinDate: '2024-01-15'
    },
    { 
      backgroundColor: 'bg-[#65D4B0]', 
      name: 'Mike Johnson', 
      email: 'mike@contoso.org', 
      phone: '(469) 532 2383',
      location: 'Los Angeles, CA',
      amountSpent: 1800,
      reviews: 10,
      referrals: 5,
      reviewCashbackOutstanding: 0,
      referralCashbackOutstanding: 1,
      performanceScore: 45,
      referredCustomers: [
        { name: 'Alex Wilson', email: 'alex@example.com', date: '2024-02-20' }
      ],
      joinDate: '2024-02-20'
    },
    { 
      backgroundColor: 'bg-[#DB005F]', 
      name: 'Sarah Smith', 
      email: 'sarah@contoso.org', 
      phone: '(469) 532 2384',
      location: 'Chicago, IL',
      amountSpent: 3200,
      reviews: 20,
      referrals: 12,
      reviewCashbackOutstanding: 1,
      referralCashbackOutstanding: 1,
      performanceScore: 92,
      referredCustomers: [
        { name: 'Tom Brown', email: 'tom@example.com', date: '2024-03-05' },
        { name: 'Lisa Jones', email: 'lisa@example.com', date: '2024-03-10' }
      ],
      joinDate: '2024-03-10'
    },
  ];

  const [customerData, setCustomerData] = useState(initialCustomerData);

  const menuOptions = [
    { label: 'Send Review Link', route: 'ReviewHistory' },
    { label: 'Send Referral Link', route: '' },
    { label: 'Send Feedback Form', route: 'FeedbackForm' }
  ];

  // Function to calculate stats for the modal
  const calculateStats = (customer) => {
    // Assuming the customer has been a member for a certain number of weeks
    const weeksActive = Math.ceil((new Date() - new Date(customer.joinDate)) / (1000 * 60 * 60 * 24 * 7));
    const averageWeeklySpending = weeksActive > 0 
      ? (customer.amountSpent / weeksActive).toFixed(2) 
      : customer.amountSpent.toFixed(2);

    // Calculate referee rating (ensure it doesn't cause NaN)
    const totalUsers = customer.referredCustomers ? customer.referredCustomers.length : 0;
    const refereeRating = totalUsers > 0 
      ? (totalUsers * 10 / 5).toFixed(1)  // Scale to 0-10 range, assuming 5 is max for a good rating
      : "0.0";

    return {
      confidencePercentage: customer.performanceScore,
      refereeRating: refereeRating,
      totalReferralRevenue: customer.referrals * 50, // Assuming $50 per referral as an example
      industrySpending: (customer.amountSpent / 10000 * 100).toFixed(2), // Assuming a $10,000 industry benchmark
      averageWeeklySpending: averageWeeklySpending
    };
  };


  const handleOptionClick = (route) => {
    navigate(route);
    setOpenDropdownIndex(null);
  };

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const toggleReferrals = (index) => {
    setOpenReferralsIndex(openReferralsIndex === index ? null : index);
  };

  const handleSort = (option) => {
    setSortOrder(option);
    let sortedData = [...customerData];

    switch (option) {
      case 'name-asc':
        sortedData.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-desc':
        sortedData.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'amount-high':
        sortedData.sort((a, b) => b.amountSpent - a.amountSpent);
        break;
      case 'amount-low':
        sortedData.sort((a, b) => a.amountSpent - b.amountSpent);
        break;
      case 'performance-high':
        sortedData.sort((a, b) => b.performanceScore - a.performanceScore);
        break;
      case 'performance-low':
        sortedData.sort((a, b) => a.performanceScore - b.performanceScore);
        break;
      default:
        break;
    }

    setCustomerData(sortedData);
  };

  // Filter customers based on name and date
  const filteredCustomers = customerData.filter(customer => 
    customer.name.toLowerCase().includes(nameFilter.toLowerCase()) &&
    (dateFilter === '' || customer.joinDate.startsWith(dateFilter))
  );

  const ThermometerGauge = ({ value, maxValue = 100, height = 120, onClick }) => {
  const percentage = (value / maxValue) * 100;
  const bulbSize = Math.max(20, height * 0.2);
  const stemWidth = Math.max(6, bulbSize * 0.3);
  
  // New color scheme using Tailwind pink and purple colors
  const getGradientColors = (percentage) => {
    const baseColors = {
      low: { from: '#ec4899', to: '#9333ea' },      // pink-500 to purple-600
      mid: { from: '#ec4899', to: '#9333ea' },      // pink-500 to purple-600
      high: { from: '#ec4899', to: '#9333ea' }      // pink-500 to purple-600
    };
    
    return baseColors[percentage < 30 ? 'low' : percentage < 70 ? 'mid' : 'high'];
  };

  const colors = getGradientColors(percentage);
  
  return (
    <div 
      className="flex flex-col items-center gap-2 cursor-pointer group mt-5" 
      onClick={onClick}
      style={{ height: height + bulbSize / 2 }}
    >
      {/* Value label */}
      <span className="text-white text-sm transition-opacity group-hover:opacity-100 opacity-80 -mt-4  font-['GeneralSansMedium']">
        {value}%
      </span>
      
      {/* Thermometer */}
      <div className="relative" style={{ height: height }}>
        {/* Stem */}
        <div 
          className="absolute left-1/2 transform -translate-x-1/2 bg-gray-700 rounded-t-lg"
          style={{ 
            width: stemWidth,
            height: height - bulbSize / 2,
            bottom: bulbSize / 2
          }}
        >
          {/* Liquid in stem */}
          <div 
            className="absolute bottom-0 w-full rounded-t-lg transition-all duration-500 ease-in-out"
            style={{ 
              height: `${percentage}%`,
              background: `linear-gradient(to top, ${colors.from}, ${colors.to})`,
              boxShadow: 'inset 0 2px 4px rgba(255,255,255,0.3)'
            }}
          />
          
          {/* Scale marks */}
          {[ 25, 50, 75, 100].map((mark) => (
            <div 
              key={mark}
              className="absolute right-full mr-1 w-2 h-px bg-gray-500"
              style={{ bottom: `${mark}%` }}
            />
          ))}
        </div>
        
        {/* Bulb */}
        <div 
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 rounded-full bg-gradient-to-r from-pink-500 to-purple-600"
          style={{ 
            width: bulbSize,
            height: bulbSize,
            boxShadow: `
              inset -2px -2px 6px rgba(0,0,0,0.3),
              inset 2px 2px 6px rgba(255,255,255,0.3),
              0 0 10px rgba(0,0,0,0.3)
            `
          }}
        >
          {/* Bulb shine effect */}
          <div 
            className="absolute w-1/3 h-1/3 bg-white rounded-full opacity-50"
            style={{ 
              top: '20%',
              left: '20%',
              filter: 'blur(1px)'
            }}
          />
        </div>
      </div>
    </div>
  );
};
  
const ThermometerModal = ({ customer }) => {
  const stats = calculateStats(customer);
  
  return (
    <div className="text-white">
      <h2 className="text-xl font-['GeneralSansMedium'] mb-6">
        Merchant Confidence Score
      </h2>
      
      <div className="flex gap-8 items-center">
        {/* Large Thermometer with scale */}
        <div className="flex items-center gap-4">
          {/* Scale numbers */}
          <div className="flex flex-col justify-between h-[200px] text-sm text-[#64718C]  font-['GeneralSansMedium']">
            {[100, 75, 50, 25, 0].map((num) => (
              <span key={num}>{num}</span>
            ))}
          </div>
          
          <ThermometerGauge 
            value={customer.performanceScore} 
            height={200}
          />
        </div>

        {/* Stats panel */}
        <div className="flex-1">
          <div className="grid gap-4">
            <div className="bg-[#1A2E56] p-4 rounded-lg">
              <p className="text-[#64718C] mb-1  font-['GeneralSansMedium']">Confidence Score</p>
              <p className="text-lg  font-['GeneralSansMedium']" style={{ color: '#ec4899' }}>{stats.confidencePercentage}%</p>
            </div>
            
            <div className="bg-[#1A2E56] p-4 rounded-lg  font-['GeneralSansMedium']">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-[#64718C]">Avg. Weekly Spending</p>
                  <p className="text-white">${stats.averageWeeklySpending}</p>
                </div>
                <div>
                  <p className="text-sm text-[#64718C]">Referee Rating</p>
                  <p className="text-white">{stats.refereeRating}</p>
                </div>
                <div>
                  <p className="text-sm text-[#64718C]">Total Referral Revenue</p>
                  <p className="text-white">${stats.totalReferralRevenue}</p>
                </div>
                <div>
                  <p className="text-sm text-[#64718C]">Industry Spending</p>
                  <p className="text-white">{stats.industrySpending}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
  
  
  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-[#0D182E] rounded-lg p-6 max-w-2xl w-full mx-4 relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white hover:text-gray-300"
          >
            <X size={24} />
          </button>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="my-24 sm:my-12 md:my-24 mx-4 sm:mx-6 md:mx-10">
      {/* Filters Section */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 mb-4 sm:mb-6 flex flex-col sm:flex-row gap-4">
        {/* Name Search */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Search Customer
          </label>
          <div className="relative">
            <input
              type="text"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              placeholder="Search by name"
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg pl-10"
            />
            <div className="absolute inset-y-0 left-0 flex items-center px-3 pointer-events-none">
              <Search className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Sort Options */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Sort By
          </label>
          <div className="relative">
            <select 
              value={sortOrder}
              onChange={(e) => handleSort(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none"
            >
              <option value="">Select Sort Option</option>
              <option value="name-asc">Name (A-Z)</option>
              <option value="name-desc">Name (Z-A)</option>
              <option value="amount-high">Amount Spent (High-Low)</option>
              <option value="amount-low">Amount Spent (Low-High)</option>
              <option value="performance-high">Performance (High-Low)</option>
              <option value="performance-low">Performance (Low-High)</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <ArrowUpDown className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Join Date
          </label>
          <div className="relative">
            <input 
              type="month"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg"
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Customer List Container */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6 md:p-10 mt-3 sm:mt-5">
        {filteredCustomers.map((customer, index) => (
          <div key={index} className="flex flex-col sm:flex-row items-start sm:items-center gap-4 mb-6 sm:mb-4 pb-4 sm:pb-4 border-b border-[#1A2E56] last:border-b-0">
            {/* Profile and Basic Info */}
            <div className="flex items-center w-full sm:w-[250px]">
              <div className={`w-[40px] h-[40px] sm:w-[45px] sm:h-[45px] transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${customer.backgroundColor}`}>
                <div className="w-full h-full -rotate-45 overflow-hidden">
                  <img 
                    src={ProfileImage} 
                    alt="Profile" 
                    className="w-[120%] h-[125%] object-contain absolute -left-[10%]" 
                  />
                </div>
              </div>
              <div className="ml-4">
                <p className="text-base text-white font-['GeneralSansMedium']">{customer.name}</p>
                <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.email}</p>
                <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.phone}</p>
                <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.location}</p>
              </div>
            </div>

            {/* Customer Metrics Table */}
            <div className="w-full sm:flex-1 overflow-x-auto">
              <table className="w-full">
                <tbody>
                  <tr className="text-sm">
                                      <td className="py-2 px-3">
                    <p className="text-[#64718C] font-['GeneralSansRegular']">Merchant Confidance Meter</p>
                      <ThermometerGauge 
                        value={customer.performanceScore} 
                        height={70}
                        onClick={() => setSelectedThermometer(customer)}
                      />
                    </td>
                     <td className="py-2 px-3">
                      <p className="text-[#64718C] font-['GeneralSansRegular']">Amount Spent</p>
                      <p className="text-white font-['GeneralSansMedium']">${customer.amountSpent}</p>
                    </td>

                       <td className="py-2 px-3">
          <p className="text-[#64718C] font-['GeneralSansRegular']">Review Cashback Completed</p>
          <p className="text-white font-['GeneralSansMedium']">{customer.reviews}</p>
        </td>
        <td className="py-2 px-3">
          <p className="text-[#64718C] font-['GeneralSansRegular']">Referral Cashback Completed</p>
          <p className="text-white font-['GeneralSansMedium']">{customer.referrals}</p>
        </td>
        <td className="py-2 px-3">
          <p className="text-[#64718C] font-['GeneralSansRegular']">Review Cashback Outstanding</p>
          <p className="text-white font-['GeneralSansMedium']">{customer.reviewCashbackOutstanding}</p>
        </td>
        <td className="py-2 px-3">
          <p className="text-[#64718C] font-['GeneralSansRegular']">Referral Cashback Outstanding</p>
          <p className="text-white font-['GeneralSansMedium']">{customer.referralCashbackOutstanding}</p>
        </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Referrals and Options Section */}
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto ml-4 sm:mt-0">
              {/* Options Dropdown */}
              <div className="relative w-full sm:w-auto">
                <button
                  onClick={() => toggleDropdown(index)}
                  className="flex items-center justify-center sm:justify-start border border-white rounded-xl px-3 py-2 bg-transparent text-white w-full sm:w-auto"
                >
                  <img 
                    src={ReviewIcon} 
                    alt="Options" 
                    className="w-4 h-4 mr-2" 
                  />
                  <span className="text-sm sm:text-base font-['GeneralSansMedium']">Options</span>
                  <ChevronDown className="ml-2 h-4 w-4" />
                </button>

                {openDropdownIndex === index && (
                  <div className="absolute right-0 mt-2 w-full sm:w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <div className="py-1">
                      {menuOptions.map((option, optionIndex) => (
                        <button
                          key={optionIndex}
                          onClick={() => handleOptionClick(option.route)}
                          className="block w-full text-left px-4 py-3 sm:py-2 text-sm text-gray-700 hover:bg-gray-100 font-['GeneralSansRegular']"
                        >
                          {option.label}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        
        {filteredCustomers.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular'] p-4">
            No customers found matching the selected filters.
          </div>
        )}
      </div>

      {/* Performance Score Modal */}
      <Modal 
  isOpen={selectedThermometer !== null} 
  onClose={() => setSelectedThermometer(null)}
>
  {selectedThermometer && (
    <ThermometerModal customer={selectedThermometer} />
  )}
</Modal>
    </div>
  );
};

export default CustomersComponent;