import React, { useState } from 'react';
import { ChevronLeft, Search, Upload, ChevronsRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const SettingsScreen = () => {
  const navigate = useNavigate();
  // State for form inputs and toggles
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [profileVisibility, setProfileVisibility] = useState('Anyone');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [director2FirstName, setDirector2FirstName] = useState('');
  const [director2LastName, setDirector2LastName] = useState('');

  const [reviewPercentage, setReviewPercentage] = useState('1');
  const [reviewFixedAmount, setReviewFixedAmount] = useState('1');
  const [reviewExpiry, setReviewExpiry] = useState('3');
  const [returnPeriod, setReturnPeriod] = useState('1');

  const [offerReferralIncentive, setOfferReferralIncentive] = useState(false);
  const [referralPercentage, setReferralPercentage] = useState('1');
  const [referralFixedAmount, setReferralFixedAmount] = useState('1');
  const [referralReturnPeriod, setReferralReturnPeriod] = useState('1');
  const [referralExpiry, setReferralExpiry] = useState('3');

  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await fetch('https://superadmin.reviewpay.com.au/logout/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      if (response.ok) {
        // Remove tokens from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Redirect to sign-in page
        navigate('/signin');
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout failed:', error);
      alert('Failed to logout. Please try again.');
    }
  };

  // Generate percentage options 1-20%
  const percentageOptions = Array.from({ length: 20 }, (_, i) => i + 1);


  // Image pick handler (mock function)
  const pickImage = () => {
    alert('Image picker would be implemented here');
  };

  // Handle 2FA toggle with redirect
  const handle2FAToggle = () => {
    if (!is2FAEnabled) {
      navigate('/UserDashboard/settings/2fasettings');
    } else {
      setIs2FAEnabled(false);
    }
  };
  const handleChangePassword = () =>
  {
    navigate("ChangePassword");
  }
  const handlebusinessdetails =() =>
  {
    navigate("BusinessDetails");
  }
  const  handlepictures =()=>{

    navigate("UploadMedia");
  }
  const handleBusinessVerification  = () =>
  {
    navigate("BusinessVerification")
  }
  const handleaddemployee =() =>{
    navigate("InviteEmployee")
  }

  // Custom Toggle Component
  const Toggle = ({ isChecked, onToggle }) => {
    return (
      <div 
        className={`w-14 h-7 rounded-full p-1 cursor-pointer transition-colors duration-300 
        ${isChecked ? 'bg-[#04A6A8]' : 'bg-gray-400'}`}
        onClick={onToggle}
      >
        <div 
          className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 
          ${isChecked ? 'translate-x-7' : 'translate-x-0'}`}
        ></div>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#4D6F8F] pb-20">
      {/* Header */}
      <div className="flex justify-between items-center p-4 pt-20 pb-10">
       
      </div>

      {/* Settings Title */}
      

      {/* Profile Information Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-6 font-['GeneralSansBold']">Directors Profile Information</h2>
        
        {/* Input Fields */}
        <div className="space-y-4">
          {/* First Name Input */}
          <div className="relative">
            <input 
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter first name"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm"> First Name</label>
          </div>

          {/* Last Name Input */}
          <div className="relative">
            <input 
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter last name"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Last Name</label>
          </div>

          {/* Admin Phone Number Input */}
          <div className="relative">
            <input 
              type="tel"
              value={adminPhoneNumber}
              onChange={(e) => setAdminPhoneNumber(e.target.value)}
              placeholder="Enter admin phone number"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Admin Phone Number</label>
          </div>

          {/* Admin Email Input */}
          <div className="relative">
            <input 
              type="email"
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
              placeholder="Enter admin email"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Admin Email</label>
          </div>

          {/* Optional Director 2 First Name Input */}
          <div className="relative">
            <input 
              type="text"
              value={director2FirstName}
              onChange={(e) => setDirector2FirstName(e.target.value)}
              placeholder="Enter second director's first name (optional)"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Director 2 First Name (Optional)</label>
          </div>

          {/* Optional Director 2 Last Name Input */}
          <div className="relative">
            <input 
              type="text"
              value={director2LastName}
              onChange={(e) => setDirector2LastName(e.target.value)}
              placeholder="Enter second director's last name (optional)"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0]"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Director 2 Last Name (Optional)</label>
          </div>

          {/* About Me Input */}
          <div className="relative">
            <textarea 
              value={aboutMe}
              onChange={(e) => setAboutMe(e.target.value)}
              placeholder="Enter About Your Business"
              className="w-full bg-transparent border border-[#959DB0] rounded-lg p-4 text-white placeholder-[#959DB0] h-24"
            />
            <label className="absolute -top-2 left-3 bg-[#0D182E] px-1 text-white text-sm">Business Description</label>
          </div>
        </div>

        {/* Profile Image Upload */}
        <div className="mt-6">
          <h3 className="text-white text-lg font-bold mb-6 font-['GeneralSansMedium']">Add Profile Image</h3>
          <div 
            onClick={pickImage}
            className="border border-[#E2E8F0] rounded-2xl h-44 flex flex-col justify-center items-center cursor-pointer"
          >
            <div className="mb-4">
              <Upload className="w-12 h-12 text-white" />
            </div>
            <p className="text-white text-base font-['GeneralSansMedium'] mb-1">
              Click to upload <span className="text-[#959DB0] text-sm">or drag and drop</span>
            </p>
            <p className="text-[#959DB0] text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
        </div>
      </div>

      {/* Account Security Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['GeneralSansBold']">Account Security</h2>
        
        {/* Two-Factor Authentication */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Enable Two-factor Authentication
          </span>
          <Toggle 
            isChecked={is2FAEnabled} 
            onToggle={handle2FAToggle} 
          />
        </div>

        {/* Change Password */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handleChangePassword}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            Change Password
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>

        {/* Manage Connected Accounts */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Manage Connected Accounts
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>

        {/* Profile Visibility */}
        <div className="flex justify-between items-center py-3">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Who can view your profile
          </span>
          <select 
            value={profileVisibility}
            onChange={(e) => setProfileVisibility(e.target.value)}
            className="bg-[#0D182E] text-white border-none"
          >
            <option value="Anyone">Anyone</option>
            <option value="Private">Private</option>
          </select>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['GeneralSansBold']">Review Cash Back Settings</h2>
        
        {/* Review Amount CashBack Percentage */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Review Amount CashBack %
          </span>
          <select 
            value={reviewPercentage}
            onChange={(e) => setReviewPercentage(e.target.value)}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded px-2 py-1"
          >
            {percentageOptions.map(percent => (
              <option key={percent} value={percent}>{percent}%</option>
            ))}
          </select>
        </div>

        {/* Review Amount CashBack Fixed */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Review Amount CashBack Fixed
          </span>
          <input 
            type="number"
            min="1"
            max="50"
            value={reviewFixedAmount}
            onChange={(e) => setReviewFixedAmount(Math.min(50, Math.max(1, e.target.value)))}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded w-20 px-2 py-1"
          />
        </div>

        {/* Return and Refund Period */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Return and Refund Period (Days)
          </span>
          <input 
            type="number"
            min="1"
            max="60"
            value={returnPeriod}
            onChange={(e) => setReturnPeriod(Math.min(60, Math.max(1, e.target.value)))}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded w-20 px-2 py-1"
          />
        </div>

        {/* Review Cashback Expiry */}
        <div className="flex justify-between items-center py-3">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Review Cashback Expiry (Months)
          </span>
          <select 
            value={reviewExpiry}
            onChange={(e) => setReviewExpiry(e.target.value)}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded px-2 py-1"
          >
            <option value="3">3 months</option>
            <option value="6">6 months</option>
            <option value="9">9 months</option>
            <option value="12">12 months</option>
          </select>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['GeneralSansBold']">Referral Cash Back Settings</h2>
        
        {/* Offer Referral Incentive Toggle */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Would you like to offer a referral incentive?
          </span>
          <Toggle 
            isChecked={offerReferralIncentive} 
            onToggle={() => setOfferReferralIncentive(!offerReferralIncentive)} 
          />
        </div>

        {/* Referral Amount CashBack Percentage */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Referral Amount CashBack %
          </span>
          <select 
            value={referralPercentage}
            onChange={(e) => setReferralPercentage(e.target.value)}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded px-2 py-1"
            disabled={!offerReferralIncentive}
          >
            {percentageOptions.map(percent => (
              <option key={percent} value={percent}>{percent}%</option>
            ))}
          </select>
        </div>

        {/* Referral Amount CashBack Fixed */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Referral Amount CashBack Fixed
          </span>
          <input 
            type="number"
            min="1"
            max="2000"
            value={referralFixedAmount}
            onChange={(e) => setReferralFixedAmount(Math.min(2000, Math.max(1, e.target.value)))}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded w-24 px-2 py-1"
            disabled={!offerReferralIncentive}
          />
        </div>

        {/* Return and Refund Period */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Return and Refund Period (Days)
          </span>
          <input 
            type="number"
            min="1"
            max="60"
            value={referralReturnPeriod}
            onChange={(e) => setReferralReturnPeriod(Math.min(60, Math.max(1, e.target.value)))}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded w-20 px-2 py-1"
            disabled={!offerReferralIncentive}
          />
        </div>

        {/* Referral Cashback Expiry */}
        <div className="flex justify-between items-center py-3">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Referral Cashback Expiry (Months)
          </span>
          <select 
            value={referralExpiry}
            onChange={(e) => setReferralExpiry(e.target.value)}
            className="bg-[#0D182E] text-white border border-[#959DB0] rounded px-2 py-1"
            disabled={!offerReferralIncentive}
          >
            <option value="3">3 months</option>
            <option value="6">6 months</option>
            <option value="9">9 months</option>
            <option value="12">12 months</option>
          </select>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['GeneralSansBold']">Account Details</h2>

        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handlebusinessdetails}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            Business Details
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handleaddemployee}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            Add Employees
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handlepictures}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            Business Pictures
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]"
        onClick={handleBusinessVerification}>
          <span className="text-white text-base font-['GeneralSansRegular']">
            Business Verification
          </span>
          <ChevronsRight className="text-white w-6 h-6" />
        </div>
      </div>


      {/* Notifications Section */}
      <div className="bg-[#0D182E] rounded-2xl mx-4 p-4 mb-4">
        <h2 className="text-white text-xl font-bold mb-4 font-['GeneralSansBold']">Notifications</h2>
        
        {/* Push Notifications */}
        <div className="flex justify-between items-center py-3 border-b border-[#959DB0]">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Push Notifications
          </span>
          <Toggle 
            isChecked={pushNotifications} 
            onToggle={() => setPushNotifications(!pushNotifications)} 
          />
        </div>

        {/* Email Notifications */}
        <div className="flex justify-between items-center py-3">
          <span className="text-white text-base font-['GeneralSansRegular']">
            Email Notifications
          </span>
          <Toggle 
            isChecked={emailNotifications} 
            onToggle={() => setEmailNotifications(!emailNotifications)} 
          />
        </div>
      </div>

      {/* Logout Button */}
      <button 
         className="bg-[#B7BDCA] mx-4 p-4 rounded-lg"
         onClick={handleLogout}
       >
         <span className="text-[#0D182E] text-base font-bold font-['GeneralSansMedium']">
           Logout
         </span>
       </button>
    </div>
  );
};

export default SettingsScreen;