import { useState } from 'react';

const ReceiptScanner = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [receiptData, setReceiptData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setError(null);
  };

  const scanReceipt = async () => {
    if (!selectedFile) {
      setError('Please select a file first');
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('api_key', 'TEST');
    formData.append('recognizer', 'auto');
    formData.append('ref_no', `ocr_react_${Date.now()}`);
    formData.append('file', selectedFile);

    try {
      const response = await fetch('http://ocr.asprise.com/api/v1/receipt', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('OCR request failed');
      }

      const data = await response.json();
      setReceiptData(data);
    } catch (err) {
      setError('Failed to scan receipt: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatValue = (value) => {
    if (value === null) return 'N/A';
    if (typeof value === 'boolean') return value.toString();
    if (typeof value === 'number') return value.toFixed(2);
    return value;
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="mb-8 bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold mb-4">Receipt Scanner</h1>
        
        <div className="mb-4">
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
        </div>

        <button
          onClick={scanReceipt}
          disabled={loading}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
        >
          {loading ? 'Scanning...' : 'Scan Receipt'}
        </button>

        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-md">
            {error}
          </div>
        )}

        {receiptData && (
          <div className="mt-8 space-y-6">
            {/* Merchant Information */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Merchant Information</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <tbody>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</td>
                      <td className="px-6 py-3">{formatValue(receiptData.merchant_name)}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</td>
                      <td className="px-6 py-3">{formatValue(receiptData.merchant_address)}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</td>
                      <td className="px-6 py-3">{formatValue(receiptData.merchant_phone)}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tax Reg No</td>
                      <td className="px-6 py-3">{formatValue(receiptData.merchant_tax_reg_no)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Receipt Details */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Receipt Details</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <tbody>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Receipt No</td>
                      <td className="px-6 py-3">{formatValue(receiptData.receipt_no)}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</td>
                      <td className="px-6 py-3">{formatValue(receiptData.date)}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</td>
                      <td className="px-6 py-3">{formatValue(receiptData.time)}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Country</td>
                      <td className="px-6 py-3">{formatValue(receiptData.country)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Items */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Items</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiptData.items && receiptData.items.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-3">{formatValue(item.description)}</td>
                        <td className="px-6 py-3">{formatValue(item.qty)}</td>
                        <td className="px-6 py-3">{formatValue(item.amount)}</td>
                        <td className="px-6 py-3">{formatValue(item.unitPrice)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Payment Information */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Payment Information</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <tbody>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subtotal</td>
                      <td className="px-6 py-3">{formatValue(receiptData.subtotal)} {receiptData.currency}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tax</td>
                      <td className="px-6 py-3">{formatValue(receiptData.tax)} {receiptData.currency}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service Charge</td>
                      <td className="px-6 py-3">{formatValue(receiptData.service_charge)} {receiptData.currency}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</td>
                      <td className="px-6 py-3 font-bold">{formatValue(receiptData.total)} {receiptData.currency}</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Method</td>
                      <td className="px-6 py-3">{formatValue(receiptData.payment_method)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* OCR Information */}
            <div>
              <h2 className="text-xl font-semibold mb-4">OCR Details</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                  <tbody>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">OCR Confidence</td>
                      <td className="px-6 py-3">{formatValue(receiptData.ocr_confidence)}%</td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image Dimensions</td>
                      <td className="px-6 py-3">{formatValue(receiptData.width)} x {formatValue(receiptData.height)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiptScanner;