import React, { useState } from 'react';
import { ChevronLeft, Search, ChevronUp, ChevronDown, BookOpenCheck } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-4 border border-[#959DB0] rounded-xl p-3">
      <button 
        className="w-full flex justify-between items-center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="text-white text-base font-['GeneralSansMedium'] mr-4 text-left">
          {question}
        </span>
        {isExpanded ? 
          <ChevronUp color="white" size={24} /> : 
          <ChevronDown color="white" size={24} />
        }
      </button>
      {isExpanded && (
        <div className="mt-3 px-2">
          <p className="text-[#B7BDCA] text-sm font-['GeneralSansRegular'] leading-5 whitespace-pre-line">
            {answer}
          </p>
        </div>
      )}
    </div>
  );
};

const FAQScreen = () => {
  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="px-4 pt-20 pb-8 flex justify-between items-center">
      </div>
      
      <div className="bg-[#0D182E] rounded-2xl p-4 mx-4">
        <FAQItem 
          question="What is ReviewPay"
          answer="ReviewPay, is an innovative digital platform founded in Melbourne in 2024, that allows businesses to reward customers cash back in exchange for brand experiences & referrals."
        />
        
        <FAQItem 
          question="What is ReviewPay for our business partners?"
          answer="Review Pay offers a way to attract and retain customers by providing them with incentives to shop on their profile or venues they also gain access to valuable data and insights about customer behaviour, which can help them optimise their marketing strategies and improve their products, services, application, operation, culture etc through the power of users/shoppers brand experiences"
        />
        
        <FAQItem 
          question="Values for Businesses"
          answer="Review Pay offers a comprehensive platform designed to enhance business growth through multiple features: Establish long-term customer relationships incentivized cash-back rewards for purchases Loyalty rewards and referral bonuses Free advertising and exposure on user's profiles Strategic insights and statistics for informed decision-making Unique opportunities for businesses to review each other Tools to expand reach with less competition Transformation of customers into sales agents Collaborative partnerships with employees Free advertising No competition ads on the Business Profile Authentic business Profile with authentic customer experience and customer feedback provides businesses with essential insights for future adjustments."
        />
        
        <FAQItem 
          question="What we offer to our Users/Shoppers?"
          answer="When customers shop by visiting our business partner venue or online, they receive a percentage of their purchase amount back as cash after they share their brand experience, which they can then withdraw or use to make future purchases on the platform. In addition to cash-back in exchange for experiences, Review Pay also offers cash-back incentives by referring family and friends."
        />
        
        <FAQItem 
          question="Values For Users"
          answer="Save-Cash Back Rewards
Users can earn cash back on every purchase they make through business partners on the app, incentivizing repeat
business and customer loyalty.

Review System
A robust review feature allows users to leave detailed reviews on their purchases, enhancing authenticity and
trustworthiness in brand interactions.

Referral Program
Users can refer friends and earn additional cash back, promoting organic growth and expanding the network of
trustworthy users within the app.

Authenticity Verification
Cashback is sent after the purchase is made with a review request whether the review content is positive or
negative, ensuring that both positive and negative feedback is credible, creating a trustworthy ecosystem.

Better Value for Money
Users can easily compare products and services based on reviews and rewards, ensuring they get the best deal for
their money.

Enhanced Customer Experience
Personalized user interfaces and tailored recommendations based on previous purchases and reviews to improve
user satisfaction.

Shop by Trust and Value
Consumers can filter their search for products based on trust ratings from authentic reviews, leading to informed
purchasing decisions.

Wishlist Showcase
Users can create wish lists and share them with followers, allowing friends to contribute input
or gift suggestions based on product reviews.

Shopping Cart Feature
A user-friendly shopping cart that allows for easy management of products before finalizing a
purchase, offering a seamless checkout experience.

Vision Board Creation
Users can create vision boards featuring desired products, which others can comment on,
fostering a community of shared interests.

Review Generator for Resumes
A unique feature that allows users to convert customer reviews into endorsements or
testimonials suitable for resumes or professional profiles.

Reduced Bad Experiences
The platform focuses on authentic reviews to help customers avoid poor choices, thereby
decreasing overall negative experiences and financial losses.

Time-Saving Features
Smart recommendations and filters help users quickly find trustworthy products, streamlining
the shopping process and saving valuable time."
        />
        <FAQItem 
          question="Review Pay Mission"
          answer="Our mission is to make the digital space more authentic and trustworthy while offering a platform that showcases businesses effectively and authentic users/shoppers can help our business partners grow and our community of users and shoppers to make informative purchasing decisions"
        />
        
        <FAQItem 
          question="Our Value"
          answer="We are grounded in trust and authenticity, driven by passion and purpose. Our values foster innovation and an intense customer and business focus."
        />
        
        <FAQItem 
          question="Our Audience"
          answer="Our platform is for everyone, as our platform aims to create a more customer-focused approach to business across all industries. By empowering businesses to learn from their clients and empowering clients to find suitable businesses, Review Pay aims to create a more authentic and transparent marketplace."
        />
        
        <FAQItem 
          question="Our Purpose"
          answer="Our goal is to create a platform where real experiences and reviews are showcased, dropping the scam's digital and fake reviews rates. By focusing on customer satisfaction, businesses can reduce their advertising costs grow organically through positive word of mouth and constantly deliver tailored brand experiences and value for money"
        />
        
        <FAQItem 
          question="Our Promise"
          answer="Our promise is to deliver a platform where real brand experiences are showcased, dropping the unauthenticity of many reviews on the digital market. By focusing on customer satisfaction, businesses can reduce their advertising costs grow organically through positive word of mouth and constantly deliver tailored brand experiences and better value for money"
        />
      </div>
    </div>
  );
};

export default FAQScreen;