import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError('');
    setIsSent(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      // Create FormData instance
      const formData = new FormData();
      formData.append('email', email);

      const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/password-reset/', {
        method: 'POST',
        // Remove the Content-Type header - browser will set it automatically with boundary
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        setIsSent(true);
        setEmail(''); 
      } else {
        setError(data.message || 'Failed to send reset link. Please try again.');
      }
    } catch (err) {
      setError('Network error. Please check your connection and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8">
        <div className="space-y-8 font-['GeneralSansRegular']">
        <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
            </div>
        </div>
      </div>

      {/* Forgot Password Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Forgot Password</h2>
        
        {error && (
          <div className="text-pink-500 px-4 py-2 rounded-lg text-lg mb-4 font-[GeneralSansRegular]">
            {error}
          </div>
        )}

        {isSent && (
          <div className="text-green-400 px-4 py-2 rounded-lg text-lg mb-4 font-[GeneralSansRegular]">
            Reset link sent successfully! Check your email.
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Email"
              required
              className="w-full px-4 py-3 border border-white/10 rounded-lg 
                       text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                       focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                       transition-all duration-300 placeholder:font-[GeneralSansRegular] bg-[#667d9c]"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading || isSent}
            className={`w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white 
                     py-3 px-4 rounded-lg font-['GeneralSansBold'] hover:opacity-90 
                     transition-all duration-300 shadow-lg hover:shadow-xl 
                     hover:scale-[1.02] disabled:opacity-70 disabled:hover:scale-100
                     ${isSent ? 'bg-green-500' : 'bg-[#0D182E] hover:opacity-90'} 
                     text-white`}
          >
            {isLoading ? 'Sending...' : isSent ? 'Sent!' : 'Send Reset Link'}
          </button>
        </form>

        <div className="mt-8 text-center">
          <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
            Remember your password?
          </span>
          <button 
            onClick={() => navigate('/signin')}
            className="text-white font-['GeneralSansMedium'] hover:underline"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;