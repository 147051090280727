import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Share2, Download, QrCode } from 'lucide-react';
import logo from "./assets/images/loogfinal.png"; 

const QRCodePage = () => {
  const qrCodeData = "https://reviewpay.com/profile/johndoe";

  const handleShareQRCode = () => {
    navigator.clipboard.writeText(qrCodeData)
      .then(() => alert('QR Code link copied to clipboard!'));
  };

  const handleDownloadQRCode = () => {
    const svgElement = document.getElementById('qr-code-svg');
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const link = document.createElement('a');
      link.download = 'QRCode.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const handleScanQRCode = () => {
    // Placeholder for QR code scanning functionality
    alert('QR Code scanning feature to be implemented');
  };

  return (
    <div className="min-h-screen bg-[#4D6F8F] py-10 flex flex-col items-center p-4">
 
      
      <div className="w-full max-w-4xl mt-32 flex flex-col items-center space-y-8">
        {/* QR Code Section */}
        <div className="flex flex-col items-center space-y-6">
          <div className="w-80 h-80 bg-[#0D182E] border-4 border-[#DC3DF7] rounded-3xl flex items-center justify-center">
            <QRCode
              id="qr-code-svg"
              value={qrCodeData}
              size={250}
              logoImage={logo}
              logoWidth={65}
              logoHeight={55}
              logoPadding={5}
              logoPaddingStyle='square'
              logoOpacity={1}
              quietZone={8}
              fgColor="#ffffff"
              bgColor="#0D182E"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col items-center space-y-4 w-full">
            <div className="flex  justify-center gap-4 ">
              <button 
                onClick={handleShareQRCode}
                className="flex-1 w-full flex items-center justify-center bg-[#0D182E] text-white px-6 py-1 rounded-lg hover:bg-[#0D182E]/90 transition-colors"
              >
                <Share2 size={20} className="mr-2" />
                Share QR
              </button>
              <button 
                onClick={handleDownloadQRCode}
                className="flex-1 w-full  flex items-center  bg-transparent text-[white] px-6 py-1 rounded-lg hover:bg-transparent transition-colors border-white border-2"
              >
                <Download size={20} className="mr-2" />
                Download QR
              </button>
            </div>
            <button 
              onClick={handleScanQRCode}
              className="w-full max-w-md flex items-center justify-center bg-[#DC3DF7] text-white px-6 py-3 rounded-lg hover:bg-[#DC3DF7]/90 transition-colors"
            >
              <QrCode size={20} className="mr-2" />
              Scan QR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodePage;