import React, { useState, useEffect } from 'react';
import { ShoppingCart } from 'lucide-react';
import logo1 from "./assets/images/company.png"
import logo2 from "./assets/images/loogfinal.png"

const TransitionModal = ({ isOpen, onClose }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 2;
        });
      }, 50);

      return () => clearInterval(interval);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#0D182E] rounded-lg p-8 max-w-md w-full mx-4">
        <div className="flex items-center justify-between space-x-8">
          {/* ReviewPay Logo */}
          <div className="w-24 h-24 bg-white rounded-lg flex items-center justify-center">
            <img
              src={logo2}
              alt="ReviewPay Logo"
              className="w-20 h-20 object-contain"
            />
          </div>

          {/* Connection Animation */}
          <div className="flex-1 relative h-2 bg-[#1A2E56] rounded-full">
            <div 
              className="absolute left-0 h-full bg-[#3DE9DC] rounded-full transition-all duration-300 ease-out"
              style={{ width: `${progress}%` }}
            />
          </div>

          {/* Merchant Logo */}
          <div className="w-24 h-24 bg-white rounded-lg flex items-center justify-center">
            <img
              src={logo1}
              alt="Merchant Logo"
              className="w-20 h-20 object-contain"
            />
          </div>
        </div>
        
        <p className="text-white text-center mt-6 font-['GeneralSansRegular']">
          Establishing secure connection...
        </p>
      </div>
    </div>
  );
};

const ShopPage = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShopClick = () => {
    setShowModal(true);

    // Define UTM parameters
    const utmParams = new URLSearchParams({
      utm_source: 'reviewpay',
      utm_medium: 'web',
      utm_campaign: 'shop_button',
      utm_content: 'main_cta',
      referral_id: '23502350',
      user_id: localStorage.getItem('accessToken')
    });

    // Construct the URL with UTM parameters
    const targetUrl = `https://spiderhuntstechnologies.com/shopback/index2.html?${utmParams.toString()}`;
    
    // Set random delay between 3-5 seconds
    const delay = Math.floor(Math.random() * 2000) + 3000;
    
    // Redirect after delay
    setTimeout(() => {
      window.location.href = targetUrl;
    }, delay);
  };

  return (
    <div className="w-full h-[1000px] bg-[#0D182E]">
      {/* Main Content */}
      <div className="bg-[#0D182E] rounded-lg p-4 sm:p-6">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">Welcome to Our Shop</h2>
        
        <div className="bg-[#1A2E56] rounded-lg p-6 text-center mb-6">
          <h3 className="text-lg sm:text-xl font-['GeneralSansMedium'] text-white mb-3">
            Discover amazing products and services
          </h3>
          <p className="text-[#64718C] font-['GeneralSansRegular'] mb-6">
            Click the button below to explore our exclusive collection
          </p>
          
          <div className="flex justify-center">
            <button
              onClick={handleShopClick}
              className="bg-[#3DE9DC] hover:bg-[#32c5bb] text-[#0D182E] font-['GeneralSansSemibold'] py-3 px-8 rounded-lg transition duration-300 flex items-center justify-center"
            >
              <ShoppingCart className="mr-2" size={20} />
              Shop Now
            </button>
          </div>
        </div>
        
        <div className="bg-[#1a2942] rounded-lg p-4 sm:p-6">
          <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-3">Featured Categories</h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {['Electronics', 'Fashion', 'Home Decor'].map((category) => (
              <div key={category} className="bg-[#1A2E56] p-4 rounded-lg">
                <p className="font-['GeneralSansRegular'] text-white">{category}</p>
                <div className="w-full bg-[#DC3DF7] rounded-full h-2 mt-2">
                  <div 
                    className="bg-[#3DE9DC] h-2 rounded-full" 
                    style={{ width: `${Math.floor(Math.random() * 40) + 60}%` }}
                  ></div>
                </div>
                <span className="text-white text-sm mt-1 inline-block font-['GeneralSansRegular']">
                  {Math.floor(Math.random() * 100) + 100} Items
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <TransitionModal 
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default ShopPage;