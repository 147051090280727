import React, { useState, useEffect } from 'react';
import { Upload, ArrowLeft, ArrowRight, Building2, MapPin, Receipt, OptionIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const SettingsBusinessRegistrationStep = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    address: '',
    category: '',
    subcategory: '',
    abnNumber: '',
    businessImages: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const [categories, setCategories] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    fetch('/categories.json')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => {
        console.error('Error loading categories:', error);
        setCategories({
          "Agriculture, Forestry & Fishing": {
            "sub-categories": ["Nursery Production", "Turf Growing"]
          },
          "Mining": {
            "sub-categories": ["Coal Mining", "Oil and Gas Extraction"]
          }
        });
      });
  }, []);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError('');

      // Get bearer token from localStorage
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      // Create FormData object for multipart/form-data submission
      const formDataToSend = new FormData();
      formDataToSend.append('businessName', formData.businessName);
      formDataToSend.append('businessAddress', formData.address);
      formDataToSend.append('abnNumber', formData.abnNumber);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('subCategory', formData.subcategory);
      
      // Add business logo image(s) as files
      if (formData.businessImages.length > 0) {
        // Add the first image as the main business logo
        formDataToSend.append('businessLogo', formData.businessImages[0]);
        
        // If there are additional images, you might need to append them differently
        // based on how your API expects multiple images
        for (let i = 1; i < formData.businessImages.length; i++) {
          formDataToSend.append('businessLogo', formData.businessImages[i]);
        }
      }

      const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/business/business_detail', {
        method: 'POST',
        headers: {
          // Don't set Content-Type here, the browser will set it automatically with the boundary parameter
          'Authorization': `Bearer ${token}`
        },
        body: formDataToSend
      });
      
      if(response.status === 401) {
        localStorage.removeItem('accessToken');
        navigate('/signin');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Business registration successful:', data);
      alert('Business registration successful!');
      

    } catch (err) {
      console.error('Error submitting business details:', err);
      setError(`Failed to register business: ${err.message || 'Please try again.'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData({ ...formData, category: selectedCategory, subcategory: '' });
    setSubcategories(selectedCategory && categories[selectedCategory] ? 
      categories[selectedCategory]['sub-categories'] : []);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileUpload = (files) => {
    const fileArray = Array.from(files);
    setFormData(prevState => ({
      ...prevState,
      businessImages: [...prevState.businessImages, ...fileArray]
    }));
  };

  const removeImage = (indexToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      businessImages: prevState.businessImages.filter((_, index) => index !== indexToRemove)
    }));
  };

  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="text-center max-w-xl mx-auto mb-8 mt-24">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
        </div>
      </div>

      {/* Registration Box */}
      <div className="bg-[#0D182E] rounded-2xl p-8 mx-4">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Business Registration</h2>
        
        <form className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="relative">
              <label className="absolute left-4  font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                Business Name
              </label>
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                <Building2 className="h-5 w-5" />
              </div>
              <input
                type="text"
                value={formData.businessName}
                onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                placeholder="Enter your business name"
                className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16"
              />
            </div>

            <div className="relative">
              <label className="absolute left-4  font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                ABN Number
              </label>
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                <Receipt className="h-5 w-5" />
              </div>
              <input
                type="text"
                value={formData.abnNumber}
                onChange={(e) => setFormData({ ...formData, abnNumber: e.target.value })}
                placeholder="Enter your ABN number"
                className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16"
              />
            </div>

            <div className="md:col-span-2 relative">
              <label className="absolute left-4 font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                Business Address
              </label>
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                <MapPin className="h-5 w-5" />
              </div>
              <input
                type="text"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                placeholder="Enter your business address"
                className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16"
              />
            </div>

            <div className="relative">
              <label className="absolute left-4  font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                Business Category
              </label>
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                <OptionIcon className="h-5 w-5" />
              </div>
              <select
                value={formData.category}
                onChange={handleCategoryChange}
                className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16 appearance-none"
              >
                <option value="" className="text-[#959DB0]">Select a category</option>
                {Object.keys(categories).map((category) => (
                  <option key={category} value={category} className="text-white">{category}</option>
                ))}
              </select>
            </div>

            <div className="relative">
              <label className="absolute left-4  font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                Business Subcategory
              </label>
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                <OptionIcon className="h-5 w-5" />
              </div>
              <select
                value={formData.subcategory}
                onChange={(e) => setFormData({ ...formData, subcategory: e.target.value })}
                disabled={!formData.category}
                className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16 appearance-none disabled:opacity-50"
              >
                <option value="" className="text-[#959DB0]">Select a subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory} className="text-white">{subcategory}</option>
                ))}
              </select>
            </div>
          </div>

          {/* File Upload */}
          <div 
            className={`mt-8 border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
              ${isDragging ? 'border-[#959DB0] bg-[#0D182E]/50' : 'border-[#959DB0] bg-[#0D182E]'}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              id="fileInput"
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={(e) => handleFileUpload(e.target.files)}
            />
            <div className="bg-[#959DB0] rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
              <Upload className="h-8 w-8 text-[#0D182E]" />
            </div>
            <p className="text-sm font-['GeneralSansMedium'] text-[#B7BDCA] mb-1">
              Click to upload or drag and drop
            </p>
            <p className="text-xs text-[#959DB0]">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </p>
          </div>

          {/* Image Preview */}
          {formData.businessImages.length > 0 && (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
              {formData.businessImages.map((image, index) => (
                <div key={index} className="relative">
                  <img 
                    src={URL.createObjectURL(image)} 
                    className="w-full h-24 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}
        </form>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-lg mx-4 mt-4">
          {error}
        </div>
      )}

      <button 
        onClick={handleSubmit}
        disabled={isSubmitting}
        className={`bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-8 flex items-center justify-center ${
          isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        <span className="text-white text-lg font-bold font-['GeneralSansMedium']">
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </span>
      </button>
    </div>
  );
};

export default SettingsBusinessRegistrationStep;