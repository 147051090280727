import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, PieChart, Pie, Cell } from 'recharts';
import { TrendingUp, Users, DollarSign, Star, ShoppingCart, Activity, Clock, Eye, Bookmark } from 'lucide-react';

// Keeping the existing data generation and sample data...
const generateData = () => {
  const data = [];
  const startDate = new Date('2012-01-01');
  const endDate = new Date('2013-02-27');
  
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 3)) {
    const baseValue = 20 + (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7);
    const randomFactor = Math.random() * 2 - 1;
    data.push({
      date: date.getTime(),
      value: parseFloat((baseValue + randomFactor).toFixed(2))
    });
  }
  return data;
};

const sampleData = generateData();

const donutData = [
  { name: 'Spent', value: 75 },
  { name: 'Revenue', value: 25 }
];

const secondDonutData = [
  { name: 'Resturants', value: 40 },
  { name: 'Clothings', value: 35 },
  { name: 'Others', value: 25 }
];

const COLORS = ['#65D4B0', '#DC3DF7'];
const SECOND_COLORS = ['#65D4B0', '#DC3DF7', '#527DE6'];

const StatCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-[#0D182E] p-6 rounded-lg">
    <div className="flex items-center gap-4 mb-2">
      <div className="p-3 bg-transparent border-2 rounded-full">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h3 className="text-lg font-['GeneralSansSemibold'] text-slate-100">{title}</h3>
    </div>
    <p className="text-3xl font-['GeneralSansMedium'] text-[#DC3DF7] mb-1">{value}</p>
  </div>
);

const Dashboard = () => {
  const [timeRange, setTimeRange] = useState('1Y');
  
  const filterData = (range) => {
    const now = new Date('2013-02-27').getTime();
    let startDate;
    
    switch(range) {
      case '1 Week':
        startDate = new Date('2013-02-20').getTime();
        break;
      case '1 Month':
        startDate = new Date('2013-01-27').getTime();
        break;
      case '1 Year':
        startDate = new Date('2012-02-27').getTime();
        break;
      default:
        startDate = new Date('2012-02-27').getTime();
    }
    
    return sampleData.filter(item => item.date >= startDate && item.date <= now);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTooltipDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <>
      <div className="px-4 pt-20 pb-8">
        <div className="bg-[#527DE6] py-4 px-4 rounded-lg mb-6">
          <h1 className="text-white text-2xl font-bold font-['VarinoRegular']">Statistics</h1>
        </div>
        {/* Charts Section */}
        <div className="w-full space-y-6">
          {/* Time Series Chart */}
          <div className="w-full p-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['GeneralSansSemibold'] text-slate-100 mb-4">Reviews</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="mb-4 flex gap-2">
              {['1 Year', '1 Month', '1 Week'].map((range) => (
                <button
                  key={range}
                  onClick={() => setTimeRange(range)}
                  className={`px-3 py-1 rounded font-['GeneralSansMedium'] ${
                    timeRange === range
                      ? 'bg-emerald-400 text-slate-900'
                      : 'bg-slate-800 text-slate-300 hover:bg-slate-700'
                  }`}
                >
                  {range}
                </button>
              ))}
            </div>
            
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={filterData(timeRange)}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#65D4B0" stopOpacity={0.8}/>
                      <stop offset="95.55%" stopColor="#65D4B0" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <CartesianGrid 
                    horizontal={true}
                    vertical={false}
                    stroke="#1E2A44"
                  />
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatDate}
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    stroke="#4B5563"
                    tick={{ fontFamily: 'GeneralSansRegular' }}
                  />
                  <YAxis 
                    stroke="#4B5563"
                    tick={{ fontFamily: 'GeneralSansRegular' }}
                  />
                  <Tooltip
                    labelFormatter={formatTooltipDate}
                    formatter={(value) => [`${value}`, 'Reviews']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    labelStyle={{ color: '#9CA3AF' }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#65D4B0"
                    fillOpacity={1}
                    fill="url(#colorValue)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* First Donut Chart */}
          <div className="w-full p-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['GeneralSansBold'] text-slate-100 mb-4">Finances</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="h-[400px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={donutData}
                    innerRadius="60%"
                    outerRadius="80%"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {donutData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value}%`, '']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Legend 
                    verticalAlign="bottom"
                    formatter={(value, entry) => (
                      <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Revenue</div>
                <div className="text-3xl font-['GeneralSansBold'] text-slate-100">$10000</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Withdraw</div>
                <div className="text-3xl font-['GeneralSansBold'] text-slate-100">$1000</div>
              </div>
            </div>
          </div>
          
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 bg-[#65D4B0] p-5 rounded-2xl">
            <StatCard 
              icon={Bookmark}
              title="Reviews"
              value="85%"
              description="Increase in monthly active users"
            />
            <StatCard 
              icon={Users}
              title="Refferals"
              value="2,345"
              description="Users active in the last 30 days"
            />
            <StatCard 
              icon={DollarSign}
              title="Revenue"
              value="$12,345"
              description="Total revenue this month"
            />
            <StatCard 
              icon={Eye}
              title="Page Visits"
              value="4.8"
              description="Average rating from users"
            />
            <StatCard 
              icon={ShoppingCart}
              title="Whishlist"
              value="1,234"
              description="Total orders this month"
            />
            <StatCard 
              icon={Clock}
              title="Time on App"
              value="78%"
              description="User engagement rate"
            />
          </div>

          {/* Second Donut Chart */}
          <div className="w-full p-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['GeneralSansBold'] text-slate-100 mb-4">Revenue Distribution</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="h-[400px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={secondDonutData}
                    innerRadius="60%"
                    outerRadius="80%"
                    dataKey="value"
                  >
                    {secondDonutData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={SECOND_COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value}%`, '']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Legend 
                    verticalAlign="bottom"
                    formatter={(value, entry) => (
                      <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Resturants 312</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Clothings 98</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Others 3</div>
                
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Dashboard;