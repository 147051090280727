import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import splashVideo from './assets/images/splash.mp4'; // Adjust the path according to your video location

const SplashScreen = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleVideoEnd = () => {
      navigate('/CommingSoon');
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
      videoElement.play().catch(error => {
        console.log("Video autoplay failed:", error);
        navigate('/welcome');
      });
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
    };
  }, [navigate]);

  return (
    <div className="fixed top-0 left-0 w-full h-full min-h-screen min-w-screen bg-black">
      <div className="relative w-full h-full">
        <video 
          ref={videoRef}
          className="absolute top-0 left-0 w-full h-full min-h-screen min-w-screen object-cover"
          playsInline
          muted
          autoPlay
          webkit-playsinline="true"
          preload="auto"
        >
          <source src={splashVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default SplashScreen;