import React, { useState, useEffect } from 'react';
import { ChevronLeft, Upload, QrCode, Share2, Download } from 'lucide-react';
import { QRCode } from 'react-qrcode-logo';

// Assuming you have this logo file in your assets
// If not, you would need to replace this with your actual logo path
import logo from "./assets/images/loogfinal.png";

const InputField = ({ label, value, onChangeText, multiline = false, placeholder, type = 'text', disabled = false }) => (
  <div className="relative mb-6">
    <label 
      className={`absolute left-4 text-white font-medium bg-[#0D182E] px-1 z-10 
        ${value ? '-top-2 text-xs text-[#B7BDCA]' : '-top-3'}`}
    >
      {label}
    </label>
    <input 
      type={type}
      className={`w-full border border-[#959DB0] rounded-lg p-4 bg-[#0D182E] 
        text-white placeholder-[#959DB0] font-['GeneralSansRegular'] 
        ${multiline ? 'h-32' : 'h-16'} ${disabled ? 'opacity-70 cursor-not-allowed' : ''}`}
      value={value}
      onChange={(e) => onChangeText(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);

const FileUpload = ({ label, onFileSelect, acceptedTypes, previewUrl, icon: Icon }) => (
  <div className="mt-6">
    <h3 className="text-white text-lg font-bold mb-6 font-['GeneralSansMedium']">{label}</h3>
    <div className="relative">
      <input
        type="file"
        onChange={onFileSelect}
        accept={acceptedTypes}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <div className="border border-[#E2E8F0] rounded-2xl h-44 flex flex-col justify-center items-center">
        {previewUrl ? (
          <img src={previewUrl} alt="Preview" className="h-full w-full object-contain rounded-2xl" />
        ) : (
          <>
            <div className="mb-4">
              <Icon className="w-12 h-12 text-white" />
            </div>
            <p className="text-white text-base font-['GeneralSansMedium'] mb-1">
              Click to upload <span className="text-[#959DB0] text-sm">or drag and drop</span>
            </p>
            <p className="text-[#959DB0] text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </>
        )}
      </div>
    </div>
  </div>
);

const QRCodeComponent = ({ qrData }) => {
  const handleShareQRCode = () => {
    navigator.clipboard.writeText(qrData)
      .then(() => alert('QR Code link copied to clipboard!'));
  };

  const handleDownloadQRCode = () => {
    const svgElement = document.getElementById('qr-code-svg');
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const link = document.createElement('a');
      link.download = 'QRCode.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const handleScanQRCode = () => {
    // Placeholder for QR code scanning functionality
    alert('QR Code scanning feature to be implemented');
  };

  return (
    <div className="mb-6 flex flex-col items-center space-y-6">
      <h3 className="text-white text-lg font-bold font-['GeneralSansMedium'] self-start">Scan Client QR Code</h3>
      
      <div className="w-64 h-64 bg-[#0D182E] border-4 border-[#DC3DF7] rounded-3xl flex items-center justify-center">
        <QRCode
          id="qr-code-svg"
          value={qrData}
          size={200}
          logoImage={logo}
          logoWidth={50}
          logoHeight={45}
          logoPadding={5}
          logoPaddingStyle='square'
          logoOpacity={1}
          quietZone={8}
          fgColor="#ffffff"
          bgColor="#0D182E"
        />
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col items-center space-y-4 w-full">
        <div className="flex justify-center gap-4 w-full">
          <button 
            onClick={handleShareQRCode}
            className="flex-1 flex items-center justify-center bg-[#0D182E] text-white px-4 py-2 rounded-lg hover:bg-[#0D182E]/90 transition-colors border border-[#959DB0]"
          >
            <Share2 size={18} className="mr-2" />
            Share QR
          </button>
          <button 
            onClick={handleDownloadQRCode}
            className="flex-1 flex items-center justify-center bg-transparent text-white px-4 py-2 rounded-lg hover:bg-transparent transition-colors border-white border-2"
          >
            <Download size={18} className="mr-2" />
            Download QR
          </button>
        </div>
        <button 
          onClick={handleScanQRCode}
          className="w-full flex items-center justify-center bg-[#DC3DF7] text-white px-6 py-3 rounded-lg hover:bg-[#DC3DF7]/90 transition-colors"
        >
          <QrCode size={18} className="mr-2" />
          Scan QR
        </button>
      </div>
    </div>
  );
};

const SendLinkScreen = () => {
  const [formData, setFormData] = useState({
    productService: '',
    invoiceTotalAmount: '',
    confirmInvoiceTotalAmount: '',
    cashbackAmount: '0',
    attachment: null,
    attachmentPreview: null,
    clientName: '',
    clientMobile: '',
    clientEmail: '',
  });
  const [modalVisible, setModalVisible] = useState(false);
  const qrCodeData = "https://reviewpay.com/profile/johndoe";

  const handleInputChange = (field, value) => {
    setFormData(prevData => {
      const updatedData = { ...prevData, [field]: value };
      
      // Update cashback amount (5% of invoice total) if invoice amount changes
      if (field === 'invoiceTotalAmount') {
        const amount = parseFloat(value) || 0;
        updatedData.cashbackAmount = (amount * 0.05).toFixed(2);
      }
      
      return updatedData;
    });
  };

  const handleFileUpload = (field, previewField) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFormData({
        ...formData,
        [field]: file,
        [previewField]: fileUrl
      });
    }
  };

  const handleSubmit = () => {
    if (formData.invoiceTotalAmount !== formData.confirmInvoiceTotalAmount) {
      alert('Invoice total amounts do not match');
      return;
    }
    
    // Log the form data including files
    console.log('Submitting form data:', {
      ...formData,
      attachment: formData.attachment?.name
    });
    
    setModalVisible(true);
  };

  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="px-4 pt-20 pb-8 flex justify-between items-center">
        <div className="flex items-center">
          {/* Header elements */}
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl p-4 mx-4">
        {/* Product/Services field as input */}
        <InputField
          label="Product/Services"
          value={formData.productService}
          onChangeText={(value) => handleInputChange('productService', value)}
          placeholder="Enter product or service"
        />
        
        <InputField
          label="Invoice Total Amount"
          value={formData.invoiceTotalAmount}
          onChangeText={(value) => handleInputChange('invoiceTotalAmount', value)}
          type="number"
          placeholder="Enter total amount"
        />
        
        <InputField
          label="Confirm Invoice Total Amount"
          value={formData.confirmInvoiceTotalAmount}
          onChangeText={(value) => handleInputChange('confirmInvoiceTotalAmount', value)}
          type="number"
          placeholder="Confirm total amount"
        />

        {/* Cashback Amount (fixed at 5% of invoice total) */}
        <InputField
          label="Review Cashback Amount"
          value={formData.cashbackAmount}
          onChangeText={(value) => handleInputChange('cashbackAmount', value)}
          type="number"
          placeholder="$ 0.00"
          disabled={true}
        />

        {/* QR Code Component */}
        <QRCodeComponent qrData={qrCodeData} />

        {/* Client Details */}
        <InputField
          label="Client Name"
          value={formData.clientName}
          onChangeText={(value) => handleInputChange('clientName', value)}
          placeholder="Enter client name"
        />
        
        <InputField
          label="Client Mobile"
          value={formData.clientMobile}
          onChangeText={(value) => handleInputChange('clientMobile', value)}
          placeholder="Enter client mobile number"
        />
        
        <InputField
          label="Client Email"
          value={formData.clientEmail}
          onChangeText={(value) => handleInputChange('clientEmail', value)}
          placeholder="Enter client email"
        />

        {/* Proof of Purchase */}
        <FileUpload
          label="Proof of Purchase"
          onFileSelect={handleFileUpload('attachment', 'attachmentPreview')}
          acceptedTypes="image/*,.pdf,.doc,.docx"
          previewUrl={formData.attachmentPreview}
          icon={Upload}
        />
      </div>
      
      <button 
        className="bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-8 flex items-center justify-center"
        onClick={handleSubmit}
      >
        <span className="text-white text-lg font-bold font-['GeneralSansMedium']">Submit</span>
      </button>

      {modalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#B7BDCA] rounded-2xl p-6 w-[30%] flex flex-col items-center">
            <h2 className="text-lg font-['GeneralSansSemibold'] text-[#0D182E] mb-4 -mt-2">
              Link Sent Successfully
            </h2>
            <p className="text-center mb-6 font-['GeneralSansRegular'] text-[#0D182E] text-base">
              Your link has been sent successfully. The recipient will receive it shortly.
            </p>
            <button 
              className="bg-[#0D182E] py-3.5 rounded-full w-[90%] flex items-center justify-center"
              onClick={() => setModalVisible(false)}
            >
              <span className="text-white text-base font-['GeneralSansMedium']">Continue</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SendLinkScreen;