import React, { useState } from 'react';
import { 
  Upload,
  Pencil,
  PackageSearch,
  TextQuote,
  DollarSign,
  Plus,
  Barcode
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png";

const ProductManagement = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([{
    id: 1,
    images: [],
    barcodeImages: [],
    name: '',
    description: '',
    price: ''
  }]);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingBarcode, setIsDraggingBarcode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addProduct = () => {
    setProducts([...products, {
      id: products.length + 1,
      images: [],
      barcodeImages: [],
      name: '',
      description: '',
      price: ''
    }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][field] = value;
    setProducts(updatedProducts);
  };

  const handleImageUpload = (index, files, type = 'images') => {
    const updatedProducts = [...products];
    const imageArray = Array.from(files);
    
    if (type === 'barcodeImages') {
      updatedProducts[index].barcodeImages = [
        ...updatedProducts[index].barcodeImages,
        ...imageArray
      ];
    } else {
      updatedProducts[index].images = [
        ...updatedProducts[index].images,
        ...imageArray
      ];
    }
    
    setProducts(updatedProducts);
    type === 'barcodeImages' ? setIsDraggingBarcode(false) : setIsDragging(false);
  };

  const removeImage = (productIndex, imageIndex, type = 'images') => {
    const updatedProducts = [...products];
    if (type === 'barcodeImages') {
      updatedProducts[productIndex].barcodeImages = updatedProducts[productIndex].barcodeImages
        .filter((_, index) => index !== imageIndex);
    } else {
      updatedProducts[productIndex].images = updatedProducts[productIndex].images
        .filter((_, index) => index !== imageIndex);
    }
    setProducts(updatedProducts);
  };

  const handleDragOver = (e, productId, type = 'images') => {
    e.preventDefault();
    type === 'barcodeImages' ? setIsDraggingBarcode(productId) : setIsDragging(productId);
  };

  const handleDragLeave = (type = 'images') => {
    type === 'barcodeImages' ? setIsDraggingBarcode(false) : setIsDragging(false);
  };

  const handleDrop = (e, index, type = 'images') => {
    e.preventDefault();
    handleImageUpload(index, e.dataTransfer.files, type);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const token = localStorage.getItem('accessToken');

    try {
      for (const product of products) {
        const formData = new FormData();
        formData.append("product_name", product.name);
        formData.append("product_description", product.description);
        formData.append("product_price", product.price);

        // Append product images
        product.images.forEach(image => {
          formData.append("product_images", image);
        });

        // Append barcode images
        product.barcodeImages.forEach(image => {
          formData.append("barcode_images", image);
        });

        const response = await fetch('https://superadmin.reviewpay.com.au/reviewpayrole_api/business/products_detail', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData,
          redirect: 'follow'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      setProducts([{
        id: 1,
        images: [],
        barcodeImages: [],
        name: '',
        description: '',
        price: ''
      }]);

      alert('Products successfully added!');
    } catch (error) {
      console.error('Error submitting products:', error);
      alert('Error adding products. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Image preview URL generation
  const getImagePreviewUrl = (file) => {
    return file instanceof File ? URL.createObjectURL(file) : file;
  };

  return (
    <div className="bg-[#4D6F8F] min-h-screen pb-[102px] flex flex-col">
      <div className="text-center max-w-xl mx-auto mb-8 mt-20">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-2xl p-8 mx-4">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Add Products-Services</h2>
        
        <div className="space-y-8">
          {products.map((product, index) => (
            <div key={product.id} className="space-y-6">
              {/* Product Images Upload */}
              <div 
                className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
                  ${isDragging === product.id 
                    ? 'border-[#959DB0] bg-[#0D182E]/50' 
                    : 'border-[#959DB0] bg-[#0D182E]'}`}
                onDragOver={(e) => handleDragOver(e, product.id)}
                onDragLeave={() => handleDragLeave()}
                onDrop={(e) => handleDrop(e, index)}
                onClick={() => document.getElementById(`productImage-${product.id}`).click()}
              >
                <input
                  id={`productImage-${product.id}`}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(index, e.target.files)}
                />
                
                {product.images.length > 0 ? (
                  <div className="grid grid-cols-3 gap-4">
                    {product.images.map((image, imageIndex) => (
                      <div key={imageIndex} className="relative">
                        <img 
                          src={getImagePreviewUrl(image)}
                          alt={`Product ${imageIndex + 1}`} 
                          className="w-full h-32 object-cover rounded-lg"
                        />
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            removeImage(index, imageIndex);
                          }}
                          className="absolute top-2 right-2 bg-red-500/70 p-2 rounded-full hover:bg-red-500"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div className="bg-[#959DB0] rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                      <Upload className="h-8 w-8 text-[#0D182E]" />
                    </div>
                    <p className="text-sm font-['GeneralSansMedium'] text-[#B7BDCA] mb-1">
                      Click to upload or drag and drop product images
                    </p>
                    <p className="text-xs text-[#959DB0]">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </div>
                )}
              </div>

              {/* Barcode Images Upload */}
              <div 
                className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
                  ${isDraggingBarcode === product.id 
                    ? 'border-[#959DB0] bg-[#0D182E]/50' 
                    : 'border-[#959DB0] bg-[#0D182E]'}`}
                onDragOver={(e) => handleDragOver(e, product.id, 'barcodeImages')}
                onDragLeave={() => handleDragLeave('barcodeImages')}
                onDrop={(e) => handleDrop(e, index, 'barcodeImages')}
                onClick={() => document.getElementById(`barcodeImage-${product.id}`).click()}
              >
                <input
                  id={`barcodeImage-${product.id}`}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(index, e.target.files, 'barcodeImages')}
                />
                
                {product.barcodeImages.length > 0 ? (
                  <div className="grid grid-cols-3 gap-4">
                    {product.barcodeImages.map((image, imageIndex) => (
                      <div key={imageIndex} className="relative">
                        <img 
                          src={getImagePreviewUrl(image)}
                          alt={`Barcode ${imageIndex + 1}`} 
                          className="w-full h-32 object-contain rounded-lg"
                        />
                        <button 
                          onClick={(e) => {
                            e.stopPropagation();
                            removeImage(index, imageIndex, 'barcodeImages');
                          }}
                          className="absolute top-2 right-2 bg-red-500/70 p-2 rounded-full hover:bg-red-500"
                        >
                          ×
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div className="bg-[#959DB0] rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                      <Barcode className="h-8 w-8 text-[#0D182E]" />
                    </div>
                    <p className="text-sm font-['GeneralSansMedium'] text-[#B7BDCA] mb-1">
                      Click to upload or drag and drop barcode/QR codes
                    </p>
                    <p className="text-xs text-[#959DB0]">
                      SVG, PNG, JPG or GIF
                    </p>
                  </div>
                )}
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="relative">
                  <label className="absolute left-4 font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                    Product Name
                  </label>
                  <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                    <PackageSearch className="h-5 w-5" />
                  </div>
                  <input
                    type="text"
                    value={product.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    placeholder="Enter product name"
                    className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16"
                  />
                </div>

                <div className="relative">
                  <label className="absolute left-4 font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                    Price
                  </label>
                  <div className="absolute left-4 top-1/2 -translate-y-1/2 text-white/50">
                    <DollarSign className="h-5 w-5" />
                  </div>
                  <input
                    type="number"
                    value={product.price}
                    onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                    placeholder="Enter price"
                    className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-16"
                  />
                </div>

                <div className="md:col-span-2 relative">
                  <label className="absolute left-4 font-medium bg-[#0D182E] px-1 z-10 -top-2 text-xs text-[#B7BDCA]">
                    Description
                  </label>
                  <div className="absolute left-4 top-8 text-white/50">
                    <TextQuote className="h-5 w-5" />
                  </div>
                  <textarea
                    value={product.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                    placeholder="Enter product description"
                    className="w-full border border-[#959DB0] rounded-lg p-4 pl-12 bg-[#0D182E] text-white placeholder-[#959DB0] font-['GeneralSansRegular'] h-32"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={addProduct}
          className="mt-8 w-full flex items-center justify-center px-6 py-3 
                     bg-[#0D182E] border border-[#959DB0] text-white rounded-lg 
                     font-['GeneralSansMedium'] hover:bg-[#0D182E]/80 transition-all duration-200"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Another Product
        </button>
      </div>

      <button 
        onClick={handleSubmit}
        disabled={isSubmitting}
        className={`bg-[#0D182E] py-5 rounded-xl w-[98%] mx-auto mt-8 flex items-center justify-center
         ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#0D182E]/80'}`}
      ><span className="text-white text-lg font-bold font-['GeneralSansMedium']">
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </span>
        </button>

      {/* Loading Overlay */}
      {isSubmitting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
    </div>
  );
};

export default ProductManagement;